@font-face {
		font-family: 'avenir_next_lt_proregular';
		src: url('../fonts/avenir_next_lt_pro_regular-webfont.woff2') format('woff2'),
				 url('../fonts/avenir_next_lt_pro_regular-webfont.woff') format('woff');
		font-weight: normal;
		font-style: normal;

}

@font-face {
		font-family: 'avenir_next_lt_probold';
		src: url('../fonts/avenir_next_lt_pro_bold-webfont.woff2') format('woff2'),
				 url('../fonts/avenir_next_lt_pro_bold-webfont.woff') format('woff');
		font-weight: normal;
		font-style: normal;

}

@import url('https://fonts.googleapis.com/css2?family=Familjen+Grotesk:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600&family=Montserrat:wght@700&display=swap');

.faq .faq-list ul {
	padding: 0;
	list-style: none;
}

.faq .faq-list li+li {
	margin-top: 15px;
}

.faq .faq-list li {
	background: #f8f8fc;
	border-radius: 10px;
	padding: 11px 30px 11px 23px;
	position: relative;
}

.faq .faq-list a {
	display: block;
	position: relative;
	font-family: 'Familjen Grotesk', sans-serif;
	font-size: 22px;
	line-height: 24px;
	font-weight: 500;
	padding: 0 0px;
	outline: none;
	cursor: pointer;
	text-decoration: none;
	color: #000;
	font-weight: 600;
}

.faq .faq-list .icon-help {
	font-size: 24px;
	position: absolute;
	right: 0;
	left: 20px;
	color: #e82c88;
}

.faq .faq-list .icon-show,
.faq .faq-list .icon-close {
	font-size: 24px;
	position: absolute;
	right: 0;
	top: 0;
}

.faq .faq-list p {
	margin-bottom: 0;
	padding: 10px 0 0 0;
}

.faq .faq-list .icon-show {
	display: none;
}

.faq .faq-list a.collapsed {
	transition: 0.3s;
}


.faq .faq-list a.collapsed .icon-show {
	display: inline-block;
}

.faq .faq-list a.collapsed .icon-close {
	display: none;
}

@media (max-width: 1200px) 
{
	.faq .faq-list 
	{
		padding: 0;
	}
}


 
i.fa.fa-sm.fa-angle-down.icon-show 
{
	background: #BC0E01;
	padding-left: 8px;
	height: 30px;
	width: 30px;
	border-radius: 100px;
	color: #fff;
	padding-top: 2px;
}
i.fa.fa-sm.fa-angle-up.icon-close 
{
	background: #BC0E01;
	padding-left: 8px;
	height: 30px;
	width: 30px;
	border-radius: 100px;
	color: #fff;
}


span.blue
{
	color: rgba(37, 38, 95, 1); font-family: 'Familjen Grotesk', sans-serif;
}
.bfr p 
{
	font-size: 22px;
	color: #000; font-family: 'Familjen Grotesk', sans-serif;

}
span.bld 
{
	font-size: 26px;
	color: rgba(37, 38, 95, 1);
	font-weight: 600;
}
.bfr 
{
	width: 100%;
	border-bottom: 1px solid #ccc;
	margin-bottom: 50px;
	padding-bottom: 20px;
	margin-top: 30px;
}
img.fix 
{
	width: 100%;
}
h1.hding 
{
	color: #000;
	font-size: 40px;
	margin-bottom: 30px; font-family: 'avenir_next_lt_probold'; font-weight: 700;
}
span.blu
{
	color: rgba(39, 40, 96, 1); 
}
ul.includes
{
	margin: 0px;
	padding: 0px;
	margin-bottom: 30px;
	margin-top: 10px;
}
ul.includes li
{
	list-style: disc;
	color: #000;
	font-size: 18px;
	margin-left: 20px; font-family: 'Familjen Grotesk', sans-serif;
	line-height: 24px;

}

h4.hding 
{
	font-weight: 400;
	font-size: 24px;
	color: rgba(0, 0, 0, 1);font-family: 'avenir_next_lt_proregular';
}
p.idea 
{
	font-weight: 400;
	font-size: 24px;
	color: rgba(0, 0, 0, 1);font-family: 'avenir_next_lt_proregular';
	line-height: 28.8px;

}
a.link 
{
	color: rgba(37, 38, 95, 1);
	font-size: 24px; font-family: 'avenir_next_lt_proregular';
}
a.patentOrderBtn 
{
	background: rgba(37, 38, 95, 1);
	color: #fff;
	font-size: 20px;
	padding: 15px 40px; font-family: 'Familjen Grotesk', sans-serif;
	margin-top:26px;
	border-radius: 0.5rem;
}
span.subhd{
color: rgba(37, 38, 95, 1);
font-weight: 600;font-size: 20px; font-family: 'Familjen Grotesk', sans-serif;
}
.genutabl .form-group{margin-top: 20px; font-family: 'Familjen Grotesk', sans-serif;} 
.form-control.bg-white-border{background: #fff; height: 60px !important; border: 0;margin-left: 15px;}
.genutabl label{font-size: 20px; margin-bottom: 15px;}
.toptext{font-size: 20px; line-height: 28px; font-weight: normal; text-align: justify; font-family: "Poppins", sans-serif;}
 
.scroll-wrap{height: 400px; overflow-y: scroll;
	overflow-x: hidden;}

/* Scrollbar Styling */
.scroll-wrap::-webkit-scrollbar 
{
	width: 10px;
}

.scroll-wrap::-webkit-scrollbar-track 
{
	background-color: #ebebeb;
	-webkit-border-radius: 10px;
	border-radius: 10px;
}

.scroll-wrap::-webkit-scrollbar-thumb 
{
	-webkit-border-radius: 10px;
	border-radius: 10px;
	background: #6d6d6d; 
}
span.ans a 
{
	display: inline !important;
	color:rgba(37, 38, 95, 1) !important;
	font-size: 14px !important;
	font-weight: normal !important;
}
span.ans 
{
	font-family: 'Familjen Grotesk', sans-serif;
	font-size: 17px;
	line-height: 24px;
	text-align: justify !important;
	display: inline-block;
	margin-left: 20px;
}
.faq-list h1 
{
 	font-family: 'avenir_next_lt_probold'; font-size: 40px;
}
section.banner 
{
	background-color:rgba(37, 38, 95, 1);
	width: 100%;
	background-image: url(/images/patent-search/video-background.png);
	background-position: -14px;
	background-repeat: no-repeat;
	background-size: cover;
	padding-top: 50px;
	padding-bottom: 30px;
	position: relative;
}

.bannerleft h1 
{
	font-size: 48px;
	color: #fff;
	font-family: 'avenir_next_lt_probold';
	font-weight: 600;
}
.bannerleft p 
{
	color: #fff;font-family: 'Familjen Grotesk', sans-serif;
	font-size: 24px;
}
.bannerleft ul 
{
	margin-left: 20px;
	padding: 0px;
	margin-top: 20px;
	margin-bottom: 15px;
}
.bannerleft li 
{
	color: #fff;font-family: 'Familjen Grotesk', sans-serif; font-size: 20px;
}
.bannerright 
{
	width: 100%;
	position: absolute;
	bottom: -100px;
}
.video 
{
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}
.bannerright img 
{
	width: 40%;

}

.videoborder video 
{
	border: 2px solid #25265F;  
	border-radius: 15px;
	object-fit: cover;
}


video 
{
	margin: 0px !important;
}

.faq-list 
{
	padding-top: 40px;
}
section#faq 
{
	background: #fff;
}
.black 
{
	color: #000000;
}
.no-padding-patent 
{
	padding-left: 0;
	padding-right: 0;
}
a[data-bs-toggle="collapse"][aria-expanded="true"] .black 
{
	color: #B5B5C9 !important; 
}

a[data-bs-toggle="collapse"][aria-expanded="true"] .blue 
{
	color: #B5B5C9 !important; 
}

a[data-bs-toggle="collapse"]:not(.collapsed) .black 
{
	color:#B5B5C9;
}

a[data-bs-toggle="collapse"]:not(.collapsed) .blue 
{
	color:#B5B5C9;
}

.shape 
{
	width: 218px;
	height: 844px;
	display: inline-block;
	position: absolute;
}
.left-shape 
{
	float: left;
	background-image: url('/images/patent-search/left-shape.png'); 
	background-size: contain;
	background-repeat: no-repeat;
	background-position: -28px -110px;
}
.right-shape 
{
	float: right;
	background-image: url('/images/patent-search/right-shape.png'); 
	background-size: 80%;
	right: 0px;
	background-repeat: no-repeat;
	background-position: 44px -120px;
}
.full-width-row 
{
 	overflow: hidden;
}
.full-width-image 
{
	width: 43vw;
	position: relative;
	left: 51%;
	right: 54%;
	margin-left: -22vw;
	margin-right: -45vw;
}

.footr-bg {
	background-image: url('/images/patent-search/footer-shape-bg.png'); 
    background-size: cover;
    background-repeat: no-repeat;
}


@media only screen and (max-width: 600px) 
{
	.faq-list .icon-show {
	    right: -20px;
	}
	span.ans { 
	   width:100%; 
	   margin-left: 0px;
	}
	.faq .faq-list a {
	    overflow-wrap: break-word;
	}
	.full-width-image 
	{
		left: 0;
		right: 0;
		margin-left: 0;
		margin-right: 0;
		width: 100%;
	}
	.embed-responsive 
	{
		position: relative;
		overflow: hidden;
		width: 100%;
		padding-top: 56.25%; /* 16:9 Aspect Ratio (divide 9 by 16 = 0.5625) */
	}
	.responsive-iframe 
	{
		position: absolute;
		top: 0;
		left: 0;
		bottom: 0;
		right: 0;
		width: 100%;
		height: 100%;
	}
	.bannerleft h1 
	{
		font-size: 32px;
	}
	.bannerleft p 
	{
		font-size: 18px;
	}
	h1.hding {font-size: 32px;}
	.faq-list h1 {font-size: 26px;}
	.videoborder iframe 
	{
		height: -webkit-fill-available;
	}
	section.banner 
	{
		background-size: cover !important;
		height: auto !important;
	}
	video#my-video 
	{
		width: 100% !important;
		height: 100% !important;
	}

	.scroll-wrap 
	{
		margin-right: -18px;
	}

	.form-control.bg-white-border 
	{
		width: 93%;
		margin-left: inherit;
	}

	.no-padding-patent 
	{
		padding-left: 15px;
		padding-right: 15px;
	}
}


* {
    margin: 0;
    padding: 0;
}

@font-face {
    font-family: "Mont";
    src: url("../fonts/Mont-HeavyDEMO.ttf");
}

body {
    background-color: #F9FBFF;
    overflow-x: hidden;
    font-family: 'Source Sans Pro';
}
.homebgcolor
{
    background-color: #F1F1F1 !important;
}
a {
    color: #000000;
    text-decoration: none;
}

h1 {
    font-size: 60px;
    font-weight: 800;
    font-family: 'Mont';
}
.downloadrfqlinks
{
    text-decoration:underline !important;
}
h2 {
    font-size: 36px;
    font-weight: 800;
    font-family: 'Mont', sans-serif;
}

h3 {
    font-size: 26px;
    font-weight: 400;
}

h4 {
    font-size: 18px;
    font-weight: 600;
}

h5 {
    font-weight: 400;
    font-size: 16px;
}

h6 {
    font-size: 14px;
    font-weight: 600;
    margin: 0;
}

.p1 {
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 23px;
    letter-spacing: 0.38em;
    text-transform: uppercase;
}

.p2 {
    font-weight: 400;
    font-size: 18px;
}

.p3 {
    font-weight: 600;
    font-size: 16px;
}

.p4 {
    font-weight: 600;
    font-size: 18px;
    text-decoration: none;
    color: black;
}

.p5 {
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 160%;
    text-align: justify;
    font-family: 'Source Sans Pro';
}

.p6 {
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 160%;
}
.pblog {
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 160%;
    text-align: left;
    font-family: 'ui-sans-serif';
}
.img-w-100 {
    max-width: 100%;
    height: auto;
}

.faqfont {
    font-weight: 400;
    font-size: 16px;
    text-align: justify;
    color: black;
}

.ratings i {
    color: #F1C44F;
}

.invetorone img {
    height: calc(100vh - 100px);
    width: auto;
    margin: 0 auto;
}

.showcaseitem img {
    height: calc(100vh - 100px);
    width: auto;
    margin: 0 auto;
}

.invetortwo img {
    height: calc(70vh - 100px);
    width: auto;
    margin: 0 auto;
}

.category img {
    height: calc(67vh - 100px);
    width: auto;
}

.invetorthree img {
    height: calc(100vh - 100px);
    width: auto;
    margin: 0 auto;
}

.caroselItemInner {
    padding: 5rem;
}

.bg-grey {
    background: rgba(41, 45, 125, 0.05);
}

.fntshowcase {
    font-size: 14px;
    font-family: 'Source Sans Pro';
    font-weight: 400;
}

.text-justified {
    text-align: justify;
}
.text-center 
{
    text-align: center;
}
.tagButtons {
    border-radius: 20px;
    font-weight: 600;
    color: white;
    padding: 0.5rem 1.6rem;
}

.bgRed {
    background-color: #de6b6b;
}

.bgLightBlue {
    background-color: #90e0ef;
}

.bgGreen {
    background-color: #46bc4b;
}

.bgYellow {
    background-color: #f1c44f;
}

.bgPink {
    background-color: #f36bc5;
}

.bgPrimary {
    background-color: #292d7d;
}

.tagsSection {
    column-gap: 0.6rem;
    row-gap: 0.6rem;
}

.fntblogcase {
    font-size: 16px;
    font-family: 'Source Sans Pro';
    font-weight: 400;
    color: rgba(17, 17, 17, 0.5);
}

.bg-main {
    background-color: #F9FBFF;
}

.iclr i {
    font-size: 36px;
    color: #292D7D !important;
}

.bottomTags {
    display: flex;
    column-gap: 1rem;
    align-items: center;
    margin-top: 4rem;
}

.blogShowTags {
    margin-top: 170px;
}

.blog2img {
    float: left;
    height: 23.188rem;
    width: 18.75rem !important;
    margin-right: 35px;
    padding: 0px !important;
    object-fit: contain !important;
}

.submit1 {
    width: 100%;
    height: 60px;
    background: #292D7D;
    border: 0.5px solid #919191;
    border-radius: 30px;
}

.floatingButton {
    position: fixed;
    right: 0;
    top: 75vh;
    z-index: 100;
}

.catbody {
    padding: 18px 10px 18px 10px;
}

.fnt-13 {
    font-size: 13px;
}

.fnt-12 {
    font-size: 12px;
}

.ratingBox {
    height: 24px;
    text-align: end;
    margin-top: -4px;
}

.iclrg {
    font-size: 36px;
    color: #C4C4C4 !important;
}

.legal_top {
    margin-top: 5.813rem;
}

.projectShowcase {
    max-height: 70vh;
    overflow: auto;
}

.projectShowcase::-webkit-scrollbar {
    width: 7px;
}

.projectShowcase::-webkit-scrollbar-track {
    background-color: #FAFAFA;
    border-radius: 100px;
}

.projectShowcase::-webkit-scrollbar-thumb {
    background-color: #C1C1C1;
    border-radius: 100px;
}

.filterList::-webkit-scrollbar {
    width: 7px;
}

.filterList::-webkit-scrollbar-track {
    background-color: #FAFAFA;
    border-radius: 100px;
}

.filterList::-webkit-scrollbar-thumb {
    background-color: #C1C1C1;
    border-radius: 100px;
}

#categoryDropDown {
    min-height: 360px;
}

.searchDropDown {
    max-height: 200px;
    min-height: 80px;
    transition: all 0.4s;
    opacity: 0;
    background-color: white;
    overflow-y: auto;
    position: absolute;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    border-radius: 0px 0px 5px 5px;
    z-index: 100;
}

.filterList {
    max-height: 59vh;
    overflow-y: auto;
    overflow-x: hidden;
    height: inherit;
    background-color: white;
}

.customcheckbox {
    position: relative;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    display: block
}

.customcheckbox input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

.checkmark {
    position: absolute;
    top: 3px;
    left: 0;
    height: 15px;
    width: 15px;
    border: 2px solid #000000;
    border-radius: 3px;
}

.checkmark2 {
    position: absolute;
    top: 3px;
    left: 0;
    height: 15px;
    width: 15px;
    border: 2px solid #919191;
    border-radius: 3px;
}

.customcheckbox label {
    margin-left: 15px;
}

.parentcheck {
    margin-left: 40px !important;
}

.checkmark2:after {
    content: "";
    position: absolute;
    display: none;
}

.customcheckbox input:checked~.checkmark2:after {
    display: block;
}

.customcheckbox .checkmark2:after {
    width: 9px;
    height: 9px;
    background: #919191;
    left: 1px;
    top: 1px;
}

.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

.customcheckbox input:checked~.checkmark:after {
    display: block;
}

.customcheckbox .checkmark:after {
    width: 9px;
    height: 9px;
    background: #000000;
    left: 1px;
    top: 1px;
}

.filtercheck>.accordion-item {
    border-bottom: none !important;
    background: none;
}

.accordion-button:not(.collapsed) {
    color: inherit !important;
    background-color: transparent !important;
    box-shadow: none !important;
}

.accordion-button {
    column-gap: 1rem;
    padding: 0.6rem 1rem;
}

.subFilterItem {
    padding-left: 2rem;
    display: flex;
    column-gap: 1.5rem;
    align-items: center;
    padding: 0.2rem 1rem;
}

.filterListItem {
    display: flex;
    column-gap: 2rem;
    align-items: center;
    color: #919191;
    font-weight: 500;
    transition: all 0.3s;
    padding: 5px 0 5px 20px;
}

.activeFilter {
    transform: scale(1.1);
    color: rgb(28, 28, 28);
    font-weight: bold;
}

.filterListItem,
.filterListItem label,
.filterListItem input {
    cursor: pointer;
    color: rgb(28, 28, 28);
    font-weight: bold;
}

.accordion-item {
    border-bottom: 1px solid rgba(0, 0, 0, 0.2) !important;
    border: none;
    background: none !important;
}

.accordion-button:focus {
    z-index: 3;
    border-color: none;
    outline: 0;
    box-shadow: none !important;
}

.qus-itm {
    padding: 3.625rem 0 4.875rem 0;
}

.catButton {
    border-radius: 20px;
    font-weight: bold;
    font-size: 18px;
    font-weight: 600;
    border: 1px solid #292D7D;
    color: #292D7D;
}


.catButtoninactive {
    font-size: 18px;
    font-weight: 600;
    border-radius: 20px;
}

.textarea_height {
    height: 140px !important;
}

.catButtoninactive:hover {
    border-radius: 20px;
    font-weight: bold;
    border: 1px solid #292D7D;
    color: #292D7D;
}

.accordion-button {
    background: #ffffff !important;
}

.showcase-title {
    padding: 2.75rem 0 2.75rem 0;
    background: rgba(41, 45, 125, 0.05);
}

.faqButton::after {
    background-image: url("../images/plus-circle.svg") !important;
}

.faqButton:not(.collapsed)::after {
    background-image: url("../images/minus-circle.svg") !important;
}

.accordion-body {
    padding: 1rem 0rem 0rem 1rem !important;
}

button.accordion-button {
    padding-left: 0px;
    padding-right: 0px;
}

.dropDownHolder {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.dropItem {
    cursor: pointer;
    padding: 0.5rem;
    border-radius: 2px;
    transition: all 0.1s;
}

.dropItem:hover {
    background-color: #e4e4e4;
}

.bg-blog {
    background-color: #eff1f9;
}

.dark-blue {
    color: #292D7D;
}

.topbar {
    background: #F9FBFF;
    height: 50px;
    padding: 0;
}

#header {
    background: #ffffff;
    height: 80px;
}

.nav-link {
    color: #111111 !important;
}

.nav-link h5 {
    margin-bottom: 0px;
}

.nav-link.active h5 {
    font-weight: 600;
}

.nav_hov:hover {
    color: #292D7D;
}

.navbar-toggler:focus {
    box-shadow: none !important;
}

.ft-link {
    color: rgba(249, 251, 255, 0.6);

}

.track {
    color: red;
    border: 2px solid red;
    border-radius: 1.5rem;
    background: white;
    padding: 10px 16px;
    font-size: 16px;
    font-weight: 600;
    text-decoration: underline;
}

.sign {
    color: white;
    background: #292D7D;
    border: 1px solid #292D7D;
    border-radius: 1.5rem;
    padding: 10px 20px;
    font-size: 16px;
    font-weight: 600;
}

.accountsign {
    color: white;
    background: #292D7D;
    border: 1px solid #292D7D;
    border-radius: 1.5rem;
    padding: 7px 14px;
    font-size: 16px;
    font-weight: 600;
}

.track:hover {
    transition: 0.4s;
    border: 2px solid red;
    background-color: red;
    color: white;
}
.profileBtn:hover {
    transition: 0.4s;
    border: 1px solid #292D7D;
    background-color: #ffffff;
    color: #292D7D;
}

.sign:hover {
    transition: 0.4s;
    border: 1px solid #292D7D;
    background-color: #ffffff;
    color: #292D7D;
}

.form-control {
    border: 1px solid #919191;
    border-radius: 5px;
    width: 100%;
    height: 60px;
    box-sizing: border-box;
}

.sb_height {
    height: 40px !important;
    margin: 0px;
    padding: 0;
}

i.fa.fa-search {
    color: #919191;
}

.form-control:focus {
    box-shadow: none;
}

.card {
    width: 100%;
    box-shadow: 0 4px 8px 0 rgb(0 0 0 / 2%), 0 6px 20px 0 rgb(0 0 0 / 2%);
    border-radius: 8px;
    border: none !important;
}
.invcard {
    box-shadow: 0 4px 8px 0 rgb(0 0 0 / 2%), 0 6px 20px 0 rgb(0 0 0 / 2%);
    border-radius: 8px;
    border: none !important;
    background : white;
}

.slick-list {
    margin: 0 -15px;
}

.slick-slide>div {
    padding: 0 15px;
}


.slick-dots li button:before {
    font-size: 14px !important;
}

.slick-dots li {
    width: 15px !important;
    margin: 0 10px !important;
}

.slick-prev:before,
.slick-next:before {
    color: #292D7D !important;
}

.slick-next {
    background-image: url("../images/next.png") !important;
    width: 28px;
    height: 28px;
    background-size: 26px !important;
    background-repeat: no-repeat;
    cursor: pointer !important;
}

.slick-prev {
    background-image: url("../images/prev.png") !important;
    width: 28px;
    height: 28px;
    background-size: 26px !important;
    background-repeat: no-repeat;
    cursor: pointer !important;
    z-index: 9999999;
}

.slick-prev:before {
    content: none !important;
}

.slick-next:before {
    content: none !important;
}

.slick-dots li.slick-active button:before {
    color: #292D7D73 !important;
}

.slick-dots li button:before {
    color: #8d8484 !important;
}

.footr-bg {
    background-color: #25265F;
}

.get {

    background: #ffffff;
    color: #292D7D;
    border: 2px solid #ffffff;
    border-radius: 1.875rem;
    padding: 12px 35px;
    font-size: 16px;
    font-weight: 600;

}

.get:hover {
    transition: 0.4s;
    border: 2px solid #ffffff;
    background-color: #292D7D;
    color: #ffffff;
}

.contact {
    padding: 12px 35px;
    background: rgba(255, 255, 255, 0.15);
    color: #ffffff;
    border: 2px solid rgba(255, 255, 255, 0.15);
    border-radius: 1.875rem;
    font-size: 16px;
    font-weight: 600;
}

.contact:hover {
    transition: 0.4s;
    border: 2px solid #292D7D;
    background-color: #ffffff;
    color: #292D7D;
}

.ft-link 
{
    font-family: 'avenir_next_lt_proregular';
    color: #CCCDFF;
    font-size: 16px;
    font-weight: 400;
}
.footer-head
{
    font-family: 'avenir_next_lt_proregular';
    color: #FFFFFF;
    font-weight: 700;
    font-size: 20px;
    line-height: 30px;
    color: #FFFFFF;
}
.footer_link p {
    margin-bottom: 7px !important;
}

.calltoaction {
    margin-top: 30px;
}

.know-more {
    margin-top: 2.125rem;
    padding: 14px 22px;
    background: #292D7D;
    color: #ffffff;
    border: 1px solid #292D7D;
    border-radius: 1.875rem;
    font-size: 18px;
    font-weight: 600;
    font-family: 'Source Sans Pro';
}

.carousel-control-prev {
    left: -60px;
}

.carousel-control-next {
    right: -60px;
}

@media (min-width: 360px) {
    .container {
        max-width: 340px !important;
    }
}

@media (min-width: 440px) {
    .container {
        max-width: 440px !important;
    }
}

@media (min-width: 540px) {
    .container {
        max-width: 540px !important;
    }
}

@media (min-width: 768px) {
    .container {
        max-width: 992px !important;
    }

}

@media (min-width: 992px) {
    .container {
        max-width: 1064px !important;
    }
}

@media (min-width: 1200px) {
    .container {
        max-width: 1260px !important;
    }
}

@media only screen and (max-width: 1260px) {
    h2 {
        font-size: 27px;
        font-weight: 600;
        font-family: 'Mont', sans-serif;
    }

    h1 {
        font-size: 27px;
        font-weight: 600;
        font-family: 'Mont', sans-serif;
    }

    .p5 {
        font-size: 18px;
    }

    .p6 {
        font-size: 16px;
    }

    .p1 {
        font-size: 13px;
    }

    .mx-4 {
        margin-left: 0px !important;
    }

    .p2 {
        font-weight: 400;
        font-size: 16px;
    }

    .track {
        font-size: 12px;
        padding: 10px 12px;
    }

    .sign {
        font-size: 12px;
        padding: 10px 12px;
    }

    .contact {
        font-size: 12px;
        padding: 10px 18px;
    }

    .get {
        font-size: 12px;
        padding: 10px 18px;
    }

    .projectShowcase {
        max-height: none !important;
        overflow: none !important;

    }

    .fil-center {
        text-align: center;
    }

    .blogShowTags {
        margin-top: 20px;
    }

    .blog2img {
        float: left;
        height: 15.188rem;
        width: 13.75rem !important;
        margin-right: 12px;
        padding: 0px !important;
        object-fit: contain !important;
    }

    .invetorone img {
        height: calc(50vh - 100px);
        width: auto;
        margin: 0 auto;
    }

    .caroselItemInner {
        padding: 1rem;
    }
}

@media (max-width: 1024px) {
    .revgap div {

        padding-bottom: 0 !important;
    }

    .revgap .p6 {

        font-size: 14px;
    }
}

@media (min-width: 1024px) and (max-width: 1160px) {
    .revgap div {

        padding-bottom: 1px !important;
    }

    .revgap .p6 {

        font-size: 15px;
    }
}

@media (min-width: 1160px) and (max-width: 1200px) {
    .revgap div {

        padding-bottom: 1.5px !important;
    }

    .revgap .p6 {

        font-size: 15px;
    }
}

@media (max-width: 991px) {
    .recent_posts h4 {
        height: 85px !important;
    }

    .mob_menu {
        position: absolute;
        top: 71px;
        right: 0px;
        width: 100%;
        max-width: 100%;
        left: 0px;
        transition: 0.3s;
        z-index: 9992;
    }

    #navinventwell {
        background: #fff;
    }

    .mob-display {
        display: block !important;
    }

    .mob-user {
        position: absolute;
        right: 75px;
    }

    #header {
        position: relative;
    }

    .parentcheck {
        margin-left: 20px !important;
        margin-top: 2px;
        font-size: 11px;
    }

    .filterList .accordion-button {
        padding: 0.6rem 0rem !important;
    }

    .catButton {

        font-size: 14px;


    }

    .catButtoninactive {
        font-size: 14px;

    }

    .qutation img {
        width: 42%;
        float: right;
    }

    .side_meta {
        margin-top: 0px !important;
    }

}

@media (max-width: 768px) {
    .megamenu {
    width: 96% !important;
    height: 320px;
    overflow: hidden;
    overflow-y:scroll !important;
}
.menu-item div {
  flex: 1 0 100% !important;
  margin: 5px;
  border-bottom: 1px solid #e7e3e3;
  height: 29px;
}

.megamenu::-webkit-scrollbar {
    width: 7px;
}

.megamenu::-webkit-scrollbar-track {
    background-color: #FAFAFA;
    border-radius: 100px;
}

.megamenu::-webkit-scrollbar-thumb {
    background-color: #C1C1C1;
    border-radius: 100px;
}

.megamenu::-webkit-scrollbar {
    width: 7px;
}

.megamenu::-webkit-scrollbar-track {
    background-color: #FAFAFA;
    border-radius: 100px;
}

.megamenu::-webkit-scrollbar-thumb {
    background-color: #C1C1C1;
    border-radius: 100px;
}

    .slider-inner-invetor #ninja-slider3-prev {
        left: -41px !important;
    }

    .slider-inner-invetor #ninja-slider3-next {
        right: -41px !important;
    }

    .slider-inner-invetor #ninja-slider-prev {
        left: -41px !important;
    }

    .slider-inner-invetor #ninja-slider-next {
        left: -41px !important;
    }

    .mbg-3 {
        margin-top: 25px;
    }

    .slick-prev {
        left: -20px;
    }

    .slick-next {
        right: -20px;
    }

    .catButton {

        font-size: 13px;
        width: 80% !important;

    }

    .catButtoninactive {
        font-size: 13px;
        width: 80% !important;
    }
}

div.sidebar_sticky {
    position: fixed;
    top: 55px;
    z-index: -1;
}

button.close {
    background: #fff;
    border: none;
    font-size: 35px;
}
.premiumcard
{
    padding : 20px;
}

.bloglist a.card img {
    object-fit: contain;
    height: 120px;
}

.card img {
    object-fit: contain;
    height: 161px;
}
.invcard img {
    object-fit: contain;
    height: 161px;
    background:white;
}
.homecard{
  -o-object-fit: cover;
     object-fit: cover !important;
  height: 202px !important;
}

.blogres .card img {
    object-fit: cover;
    height: 201px;
}

img.imgfit {
    object-fit: contain;
    height: 150px;
}

.ratingBox img,
.startRating img {
    object-fit: contain;
    height: auto;
}

.imgfitshow img {
    object-fit: contain;
    height: 60px !important;
}

.showcaseinv img {
    object-fit: contain;
    height: 150px;
}

.portfolio img {
    object-fit: contain;
    height: 113px;
}

.blogimg img {
    object-fit: contain;
    height: 102px;
    width: 122px;
}

i.fa.fa-angle-down {
    font-size: 32px;
    color: #919191;
}

.nofit-img img {
    object-fit: contain;
    height: auto;
}

.slick-slide {
    display: none;
}

.slick-initialized .slick-slide {
    display: block;
}

.introjs-button,
.introjs-button:hover,
.introjs-button:focus,
.introjs-button:active,
.introjs-disabled,
.introjs-disabled:focus,
.introjs-disabled:hover {
    background-color: #292D7D !important;
    border: 1px solid #292D7D !important;
}

@media (min-width: 991px) and (max-width: 998px) {
    .recent_posts h4 {
        height: 60px;
    }
}

.my_account {
    width: 280px;
}

.dropdesign {
    background: #F9FBFF;
    border: none;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.02), 0 6px 20px 0 rgba(0, 0, 0, 0.02);
    border-radius: 10px;
}

.btn-pd {
    padding: 0.1rem 1.1rem;
}

#msform {
    text-align: center;
    position: relative;
    margin-top: 20px;
}

.form-card {
    text-align: left;
}

#msform fieldset:not(:first-of-type) {
    display: none;
}

.btntab:hover {
    background-color: #292D7D;
}

#msform .action-button:hover,
#msform .action-button:focus {
    background-color: #292D7D;
}

.tabButtons {
    border-radius: 20px;
    font-weight: 600;
    color: #000000;
    border: 1px solid #cccccc;
    padding: 0.5rem 1.6rem;
}

.tabButtons.active {
    background-color: #292d7d;
    color: #ffffff;
}

.btn-check:checked+.btn,
.btn-check:active {
    background-color: #292d7d;
    border-color: #292d7d;
}

.btn-outline-primary {
    color: #292d7d;
    border: 1px solid #292d7d;
}

.nextbtn {
    margin-top: 2.125rem;
    padding: 7px 22px;
    background: #292D7D;
    color: #ffffff;
    border: 1px solid #292D7D;
    border-radius: 1.875rem;
    font-size: 18px;
    font-weight: 600;
    font-family: "Source Sans Pro";
}

.prevbtn {
    margin-top: 2.125rem;
    padding: 7px 22px;
    background: #292D7D;
    color: #ffffff;
    border: 1px solid #292D7D;
    border-radius: 1.875rem;
    font-size: 18px;
    font-weight: 600;
    font-family: "Source Sans Pro";
}

#msform .action-button-previous:hover,
#msform .action-button-previous:focus {
    background-color: #000000;
}

.card {
    z-index: 0;
    border: none;
    position: relative;
}
.invcard {
    z-index: 0;
    border: none;
    position: relative;
}
.invcard {
    z-index: 0;
    border: none;
    position: relative;
}
.fs-title {
    font-size: 25px;
    color: #673AB7;
    margin-bottom: 15px;
    font-weight: normal;
    text-align: left;
}

.purple-text {
    color: #673AB7;
    font-weight: normal;
}

.steps {
    font-size: 17px;
    margin-bottom: 10px;
    font-weight: bold;
    text-align: right;
}

.btntab {
    font-size: 25px;
}

.fieldlabels {
    color: gray;
    text-align: left;
}

#progressbar {
    margin-bottom: 30px;
    overflow: hidden;
    color: lightgrey;
    padding: 0;
}

#progressbar .active {
    color: #292D7D;
}
.quilldescmodal
{
    max-width : 700px;
}
textarea.form-control {
  min-height: 200px;
}
#progressbar li {
    list-style-type: none;
    font-size: 15px;
    width: 33%;
    float: left;
    position: relative;
    font-weight: 400;
}

#progressbar #account:before {
    font-family: FontAwesome;
    content: "\f13e";
}

#progressbar #personal:before {
    font-family: FontAwesome;
    content: "\f007";
}

#progressbar #payment:before {
    font-family: FontAwesome;
    content: "\f030";
}

#progressbar #confirm:before {
    font-family: FontAwesome;
    content: "\f00c";
}

#progressbar li:before {
    width: 50px;
    height: 50px;
    line-height: 45px;
    display: block;
    font-size: 20px;
    color: #ffffff;
    background: lightgray;
    border-radius: 50%;
    margin: 0 auto 10px auto;
    padding: 2px;
}

#progressbar li:after {
    content: '';
    width: 100%;
    height: 2px;
    background: lightgray;
    position: absolute;
    left: 0;
    top: 25px;
    z-index: -1;
}

#progressbar li.active:before,
#progressbar li.active:after {
    background: #292D7D;
}

.progress {
    height: 20px;
}

.progress-bar {
    background-color: #292D7D;
}

.fit-image {
    width: 100%;
    object-fit: contain;
}

/*PRICE COLOR CODE START*/
#generic_price_table .generic_content {
    background-color: #f6f6f6;
}

#generic_price_table .generic_content .generic_head_price {
    background-color: #f6f6f6;
}

#generic_price_table .generic_content .generic_head_price .generic_head_content .head_bg {
    border-color: #e4e4e4 rgba(0, 0, 0, 0) rgba(0, 0, 0, 0) #e4e4e4;
}

#generic_price_table .generic_content .generic_head_price .generic_head_content .head span {
    color: #525252;
}

#generic_price_table .generic_content .generic_head_price .generic_price_tag .price .sign {
    color: #414141;
}

#generic_price_table .generic_content .generic_head_price .generic_price_tag .price .currency {
    color: #414141;
}

#generic_price_table .generic_content .generic_head_price .generic_price_tag .price .cent {
    color: #414141;
}

#generic_price_table .generic_content .generic_head_price .generic_price_tag .month {
    color: #414141;
}

#generic_price_table .generic_content .generic_feature_list ul li {
    color: #a7a7a7;
}

#generic_price_table .generic_content .generic_feature_list ul li span {
    color: #414141;
}

#generic_price_table .generic_content .generic_feature_list ul li:hover {
    background-color: #E4E4E4;
    border-left: 5px solid #292d7d;
}

#generic_price_table .generic_content .generic_price_btn a {
    border: 1px solid #292d7d;
    color: #292d7d;
}

#generic_price_table .generic_content.active .generic_head_price .generic_head_content .head_bg,
#generic_price_table .generic_content:hover .generic_head_price .generic_head_content .head_bg {
    border-color: #292d7d rgba(0, 0, 0, 0) rgba(0, 0, 0, 0) #292d7d;
    color: #fff;
}

#generic_price_table .generic_content:hover .generic_head_price .generic_head_content .head span,
#generic_price_table .generic_content.active .generic_head_price .generic_head_content .head span {
    color: #fff;
}

#generic_price_table .generic_content:hover .generic_price_btn a,
#generic_price_table .generic_content.active .generic_price_btn a {
    background-color: #292d7d;
    color: #fff;
}

#generic_price_table {
    margin: 20px 0 20px 0;
}

.row .table {
    padding: 28px 0;
}
.coupon_header
{
    background-color: red ; 
    color: white;
    border-radius : 5px;
}
.coupon_code_id
{
    color:white;
    font-family: Mont,sans-serif;
    font-size: 36px;
    font-weight: 800;
}
/*PRICE BODY CODE START*/
#generic_price_table .generic_content {
    overflow: hidden;
    position: relative;
    text-align: center;
}

#generic_price_table .generic_content .generic_head_price {
    margin: 0 0 20px 0;
}

#generic_price_table .generic_content .generic_head_price .generic_head_content {
    margin: 0 0 50px 0;
}

#generic_price_table .generic_content .generic_head_price .generic_head_content .head_bg {
    border-style: solid;
    border-width: 90px 1411px 23px 399px;
    position: absolute;
}

#generic_price_table .generic_content .generic_head_price .generic_head_content .head {
    padding-top: 40px;
    position: relative;
    z-index: 1;
}

#generic_price_table .generic_content .generic_head_price .generic_head_content .head span {
    font-size: 18px;
    font-weight: 400;
    margin: 0;
    padding: 0;
    text-transform: uppercase;
}

#generic_price_table .generic_content .generic_head_price .generic_price_tag .price {
    display: block;
}

#generic_price_table .generic_content .generic_head_price .generic_price_tag .price .symbol {
    display: inline-block;
    font-size: 20px;
    font-weight: 400;
    vertical-align: middle;
}

#generic_price_table .generic_content .generic_head_price .generic_price_tag .price .currency {
    font-size: 40px;
    font-weight: 300;
    letter-spacing: -2px;
    line-height: 60px;
    padding: 0;
    vertical-align: middle;
}

#generic_price_table .generic_content .generic_head_price .generic_price_tag .price .cent {
    display: inline-block;
    font-size: 24px;
    font-weight: 400;
}

#generic_price_table .generic_content .generic_head_price .generic_price_tag .month {
    font-size: 18px;
    font-weight: 400;
    letter-spacing: 1px;
    ;
}

#generic_price_table .generic_content .generic_feature_list ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

#generic_price_table .generic_content .generic_feature_list ul li {
    font-size: 18px;
    padding: 6px 12px;
    transition: all 0.3s ease-in-out 0s;
    border-left: 5px solid #292d7d;
    margin-bottom: 12px;
}

.generic_feature_list {
    min-height: 257px;
}

#generic_price_table .generic_content .generic_feature_list ul li:hover {
    transition: all 0.3s ease-in-out 0s;
    -moz-transition: all 0.3s ease-in-out 0s;
    -ms-transition: all 0.3s ease-in-out 0s;
    -o-transition: all 0.3s ease-in-out 0s;
    -webkit-transition: all 0.3s ease-in-out 0s;
}

#generic_price_table .generic_content .generic_feature_list ul li .fa {
    padding: 0 10px;
}

#generic_price_table .generic_content .generic_price_btn {
    margin: 20px 0 32px;
}

#generic_price_table .generic_content .generic_price_btn a {
    border-radius: 50px;
    -moz-border-radius: 50px;
    -ms-border-radius: 50px;
    -o-border-radius: 50px;
    -webkit-border-radius: 50px;
    display: inline-block;
    font-size: 18px;
    outline: medium none;
    padding: 12px 30px;
    text-decoration: none;
    text-transform: uppercase;
}

#generic_price_table .generic_content,
#generic_price_table .generic_content:hover,
#generic_price_table .generic_content .generic_head_price .generic_head_content .head_bg,
#generic_price_table .generic_content:hover .generic_head_price .generic_head_content .head_bg,
#generic_price_table .generic_content .generic_head_price .generic_head_content .head h2,
#generic_price_table .generic_content:hover .generic_head_price .generic_head_content .head h2,
#generic_price_table .generic_content .price,
#generic_price_table .generic_content:hover .price,
#generic_price_table .generic_content .generic_price_btn a,
#generic_price_table .generic_content:hover .generic_price_btn a {
    transition: all 0.3s ease-in-out 0s;
    -moz-transition: all 0.3s ease-in-out 0s;
    -ms-transition: all 0.3s ease-in-out 0s;
    -o-transition: all 0.3s ease-in-out 0s;
    -webkit-transition: all 0.3s ease-in-out 0s;
}

@media (max-width: 320px) {}

@media (max-width: 767px) {
    #generic_price_table .generic_content {
        margin-bottom: 75px;
    }

    .select2 {
        width: 100% !important;
    }


}

@media (min-width: 768px) and (max-width: 991px) {
    #generic_price_table .col-md-3 {
        float: left;
        width: 50%;
    }

    .select2 {
        width: 100% !important;
    }

    #generic_price_table .col-md-4 {
        float: left;
        width: 50%;
    }

    #generic_price_table .generic_content {
        margin-bottom: 75px;
    }

    .megamenu {
        padding: 20px 20px;
        width: 97% !important;
    }
}

@media (min-width: 992px) and (max-width: 1199px) {
    .megamenu {
        width: 992px !important;
        margin-left: 144px;
    }
}

@media (min-width: 1200px) {}

.demo-pic {
    margin: 0 auto;
}

.demo-pic:hover {
    opacity: 0.7;
}

#generic_price_table_home ul {
    margin: 0 auto;
    padding: 0;
    list-style: none;
    display: table;
}

#generic_price_table_home li {
    float: left;
}

#generic_price_table_home li+li {
    margin-left: 10px;
    padding-bottom: 10px;
}

#generic_price_table_home li a {
    display: block;
    width: 50px;
    height: 50px;
    font-size: 0px;
}

#generic_price_table_home .blue {
    background: #3498DB;
    transition: all 0.3s ease-in-out 0s;
}

#generic_price_table_home .emerald {
    background: #292d7d;
    transition: all 0.3s ease-in-out 0s;
}

#generic_price_table_home .grey {
    background: #7F8C8D;
    transition: all 0.3s ease-in-out 0s;
}

#generic_price_table_home .midnight {
    background: #34495E;
    transition: all 0.3s ease-in-out 0s;
}

#generic_price_table_home .orange {
    background: #E67E22;
    transition: all 0.3s ease-in-out 0s;
}

#generic_price_table_home .purple {
    background: #9B59B6;
    transition: all 0.3s ease-in-out 0s;
}

#generic_price_table_home .red {
    background: #E74C3C;
    transition: all 0.3s ease-in-out 0s;
}

#generic_price_table_home .turquoise {
    background: #1ABC9C;
    transition: all 0.3s ease-in-out 0s;
}

#generic_price_table_home .blue:hover,
#generic_price_table_home .emerald:hover,
#generic_price_table_home .grey:hover,
#generic_price_table_home .midnight:hover,
#generic_price_table_home .orange:hover,
#generic_price_table_home .purple:hover,
#generic_price_table_home .red:hover,
#generic_price_table_home .turquoise:hover {
    border-bottom-left-radius: 50px;
    border-bottom-right-radius: 50px;
    border-top-left-radius: 50px;
    border-top-right-radius: 50px;
    transition: all 0.3s ease-in-out 0s;
}

#generic_price_table_home .divider {
    border-bottom: 1px solid #ddd;
    margin-bottom: 20px;
    padding: 20px;
}

#generic_price_table_home .divider span {
    width: 100%;
    display: table;
    height: 2px;
    background: #ddd;
    margin: 50px auto;
    line-height: 2px;
}

#generic_price_table_home .itemname {
    text-align: center;
    font-size: 50px;
    padding: 50px 0 20px;
    border-bottom: 1px solid #ddd;
    margin-bottom: 40px;
    text-decoration: none;
    font-weight: 300;
}

#generic_price_table_home .itemnametext {
    text-align: center;
    font-size: 20px;
    padding-top: 5px;
    text-transform: uppercase;
    display: inline-block;
}

#generic_price_table_home .footer {
    padding: 40px 0;
}

.price-heading {
    text-align: center;
}

.price-heading h1 {
    color: #666;
    margin: 0;
    padding: 0 0 50px 0;
}

.demo-button {
    background-color: #333333;
    color: #ffffff;
    display: table;
    font-size: 20px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;
    margin-bottom: 50px;
    outline-color: -moz-use-text-color;
    outline-style: none;
    outline-width: medium;
    padding: 10px;
    text-align: center;
    text-transform: uppercase;
}

.bottom_btn {
    background-color: #333333;
    color: #ffffff;
    display: table;
    font-size: 28px;
    margin: 60px auto 20px;
    padding: 10px 25px;
    text-align: center;
    text-transform: uppercase;
}

.demo-button:hover {
    background-color: #666;
    color: #FFF;
    text-decoration: none;

}

.bottom_btn:hover {
    background-color: #666;
    color: #FFF;
    text-decoration: none;
}

.text_limit_four_line {
    display: -webkit-box;
    overflow: hidden;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
}

.text_limit_one_line {
    display: -webkit-box !important;
    overflow: hidden;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
}

.text_limit_two_line {
    display: -webkit-box;
    overflow: hidden;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}

.text_limit_three_line {
    display: -webkit-box;
    overflow: hidden;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
}

.text_limit_five_line {
    display: -webkit-box;
    overflow: hidden;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
}

.text_limit_six_line {
    display: -webkit-box;
    overflow: hidden;
    -webkit-line-clamp: 6;
    -webkit-box-orient: vertical;
}

.text_limit_seven_line {
    display: -webkit-box;
    overflow: hidden;
    -webkit-line-clamp: 7;
    -webkit-box-orient: vertical;
}

.text_limit_eight_line {
    display: -webkit-box;
    overflow: hidden;
    -webkit-line-clamp: 8;
    -webkit-box-orient: vertical;
}

.text_limit_nine_line {
    display: -webkit-box;
    overflow: hidden;
    -webkit-line-clamp: 9;
    -webkit-box-orient: vertical;
}

#overlay {
    position: fixed;
    top: 0;
    z-index: 999999999;
    width: 100%;
    left: 0;
    height: 100%;
    display: none;
    background: rgba(0, 0, 0, 0.6);
}

.cv-spinner {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.spinner {
    width: 40px;
    height: 40px;
    border: 4px #ddd solid;
    border-top: 4px #2b2f7e solid;
    border-radius: 50%;
    animation: sp-anime 0.8s infinite linear;
}

@keyframes sp-anime {
    100% {
        transform: rotate(360deg);
    }
}

.mainblogsidebar {
    margin-top: -93px;
}

.homeloader {
    min-height: 600px;
}

.textreg {
    overflow: hidden;
    height: 165px;
}

.textregvendor {
    overflow: hidden;
    height: 287px;
}

span.select2-selection.select2-selection--single.homedropdown {
    border: none;
    background: none;
    height: 35px;
}

span.select2-selection.select2-selection--single.invdropdown {
    border: none;
    background: none;
    height: 35px;
}

span.select2-selection.select2-selection--single.blogdropdown {
    border: none;
    background: none;
    height: 35px;
}

span.select2-selection.select2-selection--single.catedropdown {
    border: none !important;
    height: 60px !important;
    box-shadow: 0 0.5rem 1rem rgb(0 0 0 / 15%) !important;
}

.catedropdown .select2-selection__rendered {
    line-height: 60px !important;
    border-radius: 5px !important;

}

.catedropdown .select2-selection__arrow {
    height: 35px !important;
    position: absolute !important;
    top: 10px !important;
    right: 13px !important;
    width: 36px !important;
}

span.select2-selection.select2-selection--single.countrydropdown {
    border: none !important;
    height: 60px !important;
    box-shadow: 0 0.5rem 1rem rgb(0 0 0 / 15%) !important;
}

.countrydropdown .select2-selection__rendered {
    line-height: 60px !important;
    border-radius: 5px !important;

}

.countrydropdown .select2-selection__arrow {
    height: 35px !important;
    position: absolute !important;
    top: 10px !important;
    right: 13px !important;
    width: 36px !important;
}

.select2-container--default .select2-selection--single .select2-selection__clear {
    cursor: pointer;
    float: right;
    font-weight: bold;
    height: 26px;
    margin-right: 20px;
    padding-right: 10px !important;
    font-size: 17px;
    color: #989191 !important;
}




.select2-container--default .select2-results__option--highlighted.select2-results__option--selectable {
    background-color: #292d7d !important;
    color: white;
}

.select2-search--dropdown {
    padding: 8px !important;
}

.select2-results__option {
    padding: 8px !important;
}

.select2-results__options::-webkit-scrollbar-thumb {
    background-color: #C1C1C1;
    border-radius: 100px;
}

.select2-container--open .select2-dropdown--below {
    border: none !important;
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}

.select2-results__options::-webkit-scrollbar {
    width: 7px;
}

.select2-results__options::-webkit-scrollbar-track {
    background-color: #FAFAFA;
    border-radius: 100px;
}

.select2-results__options::-webkit-scrollbar-thumb {
    background-color: #C1C1C1;
    border-radius: 100px;
}

input.select2-search__field:focus {
    outline: none;
}

.select2-container--default .select2-selection--single .select2-selection__arrow b {
    display: none !important;
}

.card-body {
    min-height: 151px;
}

.blogres .card-body {
    min-height: 218px;
}

.testi_brands img {
    object-fit: contain;
    height: 57px;
}

.qutation {
    margin-left: 29px;
}

.catbody {
    min-height: inherit;
}

#wrapper {
    padding-left: 0;
    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    transition: all 0.5s ease;
}

#wrapper.toggled {
    padding-left: 250px;
}

#sidebar-wrapper {
    z-index: 1000;
    position: sticky;
    left: 250px;
    width: 0;
    height: 100%;
    margin-left: -250px;
    overflow-y: auto;
    background: #000;
    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    transition: all 0.5s ease;
}

#wrapper.toggled #sidebar-wrapper {
    width: 250px;
}

#page-content-wrapper {
    width: 100%;
    position: absolute;
    padding: 15px;
}

#wrapper.toggled #page-content-wrapper {
    position: absolute;
    margin-right: -250px;
}

/* Sidebar Styles */

.sidebar-nav {
    position: absolute;
    top: 0;
    width: 250px;
    margin: 0;
    padding: 0;
    list-style: none;
}

.sidebarnav-admin {
    width: 230px;
}

.card-header {
    background: #fff;
}

.sidebar-nav li {
    text-indent: 20px;
    line-height: 40px;
}

.sidebar-nav li a {
    display: block;
    text-decoration: none;
    color: white;
}

.sidebar-nav li a:hover {
    text-decoration: none;
    color: #fff;
    background: rgba(255, 255, 255, 0.2);
}

.sidebar-nav li a:active,
.sidebar-nav li a:focus {
    text-decoration: none;
}

.sidebar-nav>.sidebar-brand {
    height: 65px;
    font-size: 18px;
    line-height: 60px;
}

.sidebar-nav>.sidebar-brand a {
    color: #999999;
}

.sidebar-nav>.sidebar-brand a:hover {
    color: #fff;
    background: none;
}

@media(min-width:768px) {
    #wrapper {
        padding-left: 250px;
    }

    #wrapper.toggled {
        padding-left: 0;
    }

    #sidebar-wrapper {
        width: 250px;
    }

    #wrapper.toggled #sidebar-wrapper {
        width: 0;
    }

    #page-content-wrapper {
        padding: 20px;
        position: relative;
    }

    #wrapper.toggled #page-content-wrapper {
        position: relative;
        margin-right: 0;
    }
}

.js-sidebar ul li {
    line-height: 35px;
}
.align-middle
{
    font-size: 17px !important;
}
.sidebar-header {
    padding: 10px !important;
}

.sidebar-header 
{
    font-size: 22px !important;
}
.sidebar-link,
a.sidebar-link {
    padding: 7px !important;
}

.categoryimgsize {
    -o-object-fit: contain;
    object-fit: contain;
    height: 103px !important;
}

img.img-rounded {
    border-radius: 50%;
    width: 70px;
    height: 70px;
}

.dropzone-wrapper {
    border: 2px dashed #91b0b3;
    color: #92b0b3;
    position: relative;
    height: 150px;
}

.dropzone-desc {
    position: absolute;
    margin: 0 auto;
    left: 0;
    right: 0;
    text-align: center;
    width: 40%;
    top: 50px;
    font-size: 16px;
}

.dropzone,
.dropzone:focus {
    position: absolute;
    outline: none !important;
    width: 100%;
    height: 150px;
    cursor: pointer;
    opacity: 0;
}

.dropzone-wrapper:hover,
.dropzone-wrapper.dragover {
    background: #ecf0f5;
}

.preview-zone {
    text-align: center;
}

.glyphicon-plus-sign {
    color: white;
    font-size: 40px;
}

.preview-zone .box {
    box-shadow: none;
    border-radius: 0;
    margin-bottom: 0;
}

.list-group-item {
    background: none !important;
}

.menu-large {
    position: static !important;
}

.megamenu {
    padding: 20px 20px;
    width: 1366px;
    left: -340px !important;
}

.megamenu>div>li>ul {
    padding: 0;
    margin: 0;
}

li.dropdown.menu-large.nav-item h5 {
    float: left;
}

.dropdown-toggle:after {
    margin-left: 5px !important;
}
.menu-item {
  display: flex !important;
  flex-wrap: wrap;
}
.menu-item div {
  flex: 1 0 21%;
  margin: 5px;
  border-bottom: 1px solid #e7e3e3;
  height: 29px;
}
.recent_posts h4 {
    height: 55px;
}
.box {
    box-shadow: 0 4px 8px 0 rgb(0 0 0 / 2%), 0 6px 20px 0 rgb(0 0 0 / 2%);
    border-radius: 8px;
    background: #fff;
    border: none !important;
}
.edit_cursor{
    cursor: text;
    padding:5px;
    border: 2px solid transparent;
}

.edit_cursor:hover {
   padding:5px;
   border:2px dashed #292D7D; 
}

.box  input {
    border:none;
}
.box  input:focus {
    border:none;
    box-shadow: none;
}
.quote-list h1 {
    font-size: 17px;
}
.typebtn
{
position: fixed !important;
top: 15%;
height: 45px;
right: 0%;
z-index: 99999;
}

.introjs-skipbutton
{
    color: white !important;
}

.icon-bar {
  position: fixed;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  right: 0%;
  z-index: 99999;
}

.icon-bar a {
  display: block;
  text-align: center;
  padding: 10px;
  transition: all 0.3s ease;
  color: white;
  font-size: 14px;
}

.icon-bar a:hover {
  background-color: #000;
}

.facebook {
  background: #3B5998;
  color: white;
}
.whatsapp {
  background: #4CBB17;
  color: white;
}
.youtube 
{
  background-image: red;
  color: white;
}
.instagram 
{
  background-image: linear-gradient(#feda75,#fa7e1e,#d62976,#962fbf,#4f5bd5);
  color:#fff;
}
.twitter 
{
  background: #55ACEE;
  color: white;
}

.google {
  background: #dd4b39;
  color: white;
}

.linkedin {
  background: #007bb5;
  color: white;
}

.youtube {
  background: #bb0000;
  color: white;
}

.form-label {
    font-size: 20px !important;
    font-weight: bold !important;
}
.form-control {
    height: 40px !important;
    font-size: 1.25rem !important;
    font-weight: 400 !important;
}
.form-group.required .form-label:after {
  content:"*";
  color:red;
}

.vendor-foot-btn {
    color: white;
    background: cornflowerblue;
    border: 1px solid #292D7D;
    border-radius: 1.5rem;
    padding: 10px 20px;
    font-size: 16px;
    font-weight: 600;
}
.vendor-disable-foot-btn {
    color: black;
    //background: cornflowerblue;
    border-radius: 1.5rem;
    padding: 10px 20px;
    font-size: 16px;
    font-weight: 600;
}
.vendor-enable-foot-btn {
    color: black;
    background: darkorange;
    border: darkorange;
    border-radius: 1.5rem;
    padding: 10px 20px;
    font-size: 16px;
    font-weight: 600;
}
//ticker 

@-webkit-keyframes ticker {
  0% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    visibility: visible;
  }
  100% {
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }
}
@keyframes ticker {
  0% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    visibility: visible;
  }
  100% {
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }
}
.ticker-wrap {
  bottom: 0;
  overflow: hidden;
  height: 4rem;
  box-sizing: content-box;
}
.ticker-wrap .ticker {
  display: inline-block;
  height: 4rem;
  line-height: 4rem;
  white-space: nowrap;
  box-sizing: content-box;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
  -webkit-animation-name: ticker;
  animation-name: ticker;
  -webkit-animation-duration: 30s;
  animation-duration: 30s;
}
.ticker-wrap .ticker__item {
  display: inline-block;
  padding: 0 2rem;
  font-size: 20px;
  color: #FF8C00;
}


.hyperlinkcolor
{
    color:#55ACEE !important;
}
.categoryVendorTag
{
    color:white;
    background:red;
    font-size: 25px;
    padding-bottom: 5px;
    padding-top: 1px;
    border-radius: 5px;
}


.profileBtn {
    color: white;
    background: #55acee;
    border: 1px solid #55acee;
    border-radius: 1.5rem;
    padding: 10px 20px;
    font-size: 16px;
    font-weight: 600;
}

//ziptoggle


.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.switch input { 
  opacity: 0;
  width: 0;
  height: 0;
}

.ziptoggle {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.ziptoggle:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .ziptoggle {
  background-color: #2196F3;
}

input:focus + .ziptoggle {
  box-shadow: 0 0 1px #2196F3;
}

input:checked + .ziptoggle:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.ziptoggle.round {
  border-radius: 34px;
}

.ziptoggle.round:before {
  border-radius: 50%;
}
.content-justify
{
    text-align:justify;
}
.colorRed
{
    color:red;
}
.colorGreen
{
    color:green;
}

/* Subscription card  */
.card-cvv, .card-expiry, .card-number 
{
    color: #32325d;
    font-family: "Helvetica Neue", Helvetica, sans-serif;
    font-smoothing: antialiased;
    font-size: 16px;
}
.card-cvv::placeholder, .card-expiry::placeholder, .card-number::placeholder 
{
    color: #aab7c4;
}

#card-errors 
{
    color: #fa755a;
    icon-color: #fa755a;
}
.homeCategoryCard
{
    font-family: 'Montserrat', sans-serif;
    font-size : 16px;
    position: relative;
    padding: 0;
    border: 0;
    outline: 0;
    text-align:center;
    vertical-align: baseline;
    background: transparent;
}


.workopen 
{
    color: white;
}

.workstatus 
{
    background-color: #f89d13;
}

.feeddialog,
.externalmodalbody
{
    width: 1250px;
}

.modalContent 
{
    background-color: transparent;
    border: 0px;
}

.NoQuote 
{
    background-color: #f89d13;
}

.accountstatus,

#chat-msg 
{
    height: 382px;
}
#admin-communication-list-datatable,
#admin-rating-review-list-datatable,
#acconttable,
#workstream-datatable,
#vendor-rating-datatable,
#vendor-workstream-datatable
{
    width: 100%;
}
.rating-widget,
.commentreson,
.reviewstatus,
.write,
.rating-tag,
.rating-date,
#project-new,
.workclose
{
   display: block;         
}
#filename
{
    padding-left: 35px; 
    width: 400px;
}
.quote-list
{
    height: 435px;
    overflow-y: auto;
}
#ratingSelect
{
    width: 535px;
}

.workclose,
.workopen,
#close
{
    color: white;
}
#stagechart,
#paymentchart
{
    width: 90%;
}
.project-filter
{
    float: right;
}
#downloadtype,
#projects
{
    width: 200px;
}
.ptitle
{
    align: center;
}
.reviewComments
{
    width: 450px;
}
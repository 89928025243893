/* Visit http://www.menucool.com/responsive-slider for instructions */

#ninja-slider {
    width:100%;
    margin:0 auto;
    overflow:hidden;
    box-sizing:border-box;
}

#ninja-slider.fullscreen {
    background:#000;
}


#ninja-slider .fs-icon {
    top:6px;
    right:6px;
    width:60px;
    height:26px;
    background: rgba(0,0,0,0.2);
    z-index:2;
    color:white;
    text-align:center;
    font:bold 11px/26px arial;
    border:1px solid rgba(255,255,255,0.3);
    border-radius:2px;
    opacity:0;
    -webkit-transition:opacity 0.8s;
    transition:opacity 0.8s;
}

#ninja-slider .slider-inner:hover .fs-icon,
#ninja-slider.fullscreen .fs-icon {
    opacity: 1;
}
#ninja-slider.fullscreen div.fs-icon {
    right:30px;
}

#ninja-slider .fs-icon::before {      
    content:"EXPAND";
    display:block;
}

#ninja-slider.fullscreen .fs-icon::before {
    content:"CLOSE";
}


#ninja-slider .slider-inner {
    margin:0 auto;/*center-aligned */
    font-size:0px;
    position:relative;
    box-sizing:border-box;
}

#ninja-slider .slider-inner-invetor {
    margin:0 auto;/*center-aligned */
    font-size:0px;
    width:80%;
    position:relative;
    box-sizing:border-box;
}

#ninja-slider .slider-inner-invetor-two {
    margin:0 auto;/*center-aligned */
    font-size:0px;
    width:79%;
    position:relative;
    box-sizing:border-box;
}

#ninja-slider.fullscreen .slider-inner {
    width:100%;
}

#ninja-slider ul {
    position:relative;
    list-style:none;
    padding:0;
    box-sizing:border-box;
}

#ninja-slider li {
    
    width:100%;
    height:100%;
    top:0;
    left:0;
    position: absolute;
    font-size:12px;
    list-style:none;
    margin:0;
    padding:0;
    opacity:0;
    overflow:hidden;
    box-sizing:border-box;
}

#ninja-slider li.ns-show {
    opacity:1;
}



/* --------- slider image ------- */  
#ninja-slider .ns-img {
    
    background-color:inherit;
    background-size:contain;/*Note: If transitionType is zoom, it will be overridden to cover.*/
    /*decorate border*/
    box-shadow: inset 0 0 2px rgba(255,255,255,.3);
    cursor:default;
    display:block;
    position: absolute;
    width:100%;
    height:100%;
    background-repeat:no-repeat;
    background-position:center center;
}


/*---------- Captions -------------------*/
#ninja-slider .caption {
    padding:20px 40px;
    margin:auto;
    left:0;right:0;
    top:auto;bottom:0px;
    width:auto;
    text-align:right;
    font-family:sans-serif;
    font-size:11px;
    color:rgba(255,255,255,0.2);
    background:none;
    /*background:rgba(0,0,0,0.12);*/
    position:absolute;
    box-sizing:border-box;
}
#ninja-slider li .cap1 {
    color:rgba(255,255,255,0.5);
    font:bold 32px arial;
    text-align:center;

    opacity:0;
    bottom:40%;
    -webkit-transition:bottom 1.3s, opacity 1s;
    transition:bottom 1.3s, opacity 1s;
}

/*The cap1 of the current slide */
#ninja-slider li[class*='-'] .cap1 {
    opacity:1;
    bottom:20%;
}
#ninja-slider li .cap2 {
    color:rgba(255, 162, 0, 0.5);
    font-size:23px;
    bottom:15%;
    -webkit-transition-delay: 1s;
    transition-delay: 1s;
}

/*The cap2 of the current slide */
#ninja-slider li[class*='-s'] .cap2 {
    bottom:10%;
}


/* ---------Arrow buttons ------- */   
/* The arrow button id should be: slider id + ("-prev", "-next", and "-pause-play") */ 
#ninja-slider-pause-play { display:none;}  

#ninja-slider-prev, #ninja-slider-next
{
    position: absolute;
    display:inline-block;
    width:42px;
    height:56px;
    line-height:56px;
    top: 50%;
    margin-top:-51px;/*pager's height should also be counted*/
    background-color:#ccc\9;/*IE8 hack*/
    backface-visibility:hidden;
    color:white;
    overflow:hidden;
    white-space:nowrap;
    -webkit-user-select: none;
    user-select:none;
    border-radius:2px;
    z-index:10; 
    font-family:sans-serif;   
    font-size:13px;
    cursor:pointer;
    -webkit-transition:all 0.7s;
    transition:all 0.7s;
}

.slider-inner:hover #ninja-slider-prev, .slider-inner:hover #ninja-slider-next {
    opacity:1;
}


#ninja-slider-prev {
    left: 10px; /*set it with a positive value if .slider-inner does not have max-width setting.*/
}
#ninja-slider-next {
    right: 10px;
}

/* arrows */
#ninja-slider-prev::before, #ninja-slider-next::before {
    position: absolute;
    top: 17px;
    content: "";
    display: inline-block;
    width: 20px;
    height: 20px;
    border-left: 2px solid white;
    border-top: 2px solid white;
    backface-visibility:hidden;
}

#ninja-slider-prev::before {
    -ms-transform:rotate(-45deg);/*IE 9*/
    -webkit-transform:rotate(-45deg);
    transform: rotate(-45deg);
    right:4px;
}

#ninja-slider-next::before {
    -ms-transform:rotate(135deg);/*IE 9*/
    -webkit-transform:rotate(135deg);
    transform: rotate(135deg);
    left:4px;
}


/*pagination num within the arrow buttons*/
#ninja-slider-prev div, #ninja-slider-next div {display:none;}


/*------ pager(nav bullets) ------*/      
/* The pager id should be: slider id + "-pager" */
/* #ninja-slider-pager { display:none;}*/
#ninja-slider-pager
{
    padding-top:10px; /* set the distance away from the slider */
    margin:0 auto; /* center align */
    text-align:center;
    display:table;
    font-size:0;
}
         
#ninja-slider-pager a 
{
    display:inline-block;
    width: 12px;
    height: 12px;
    background-color: #CCC;
    font-size:0; /* Non-zero will show the index num */
    margin:2px 8px;
    cursor:pointer;
    border-radius:10px;
}

#ninja-slider-pager a:hover 
{
    opacity:0.6;
}
#ninja-slider-pager a.active 
{
    background-color:rgba(41, 45, 125, 0.45);
}

   

#ninja-slider-pager, #ninja-slider-prev, #ninja-slider-next, #ninja-slider-pause-play
{
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    user-select: none;
}


/*Responsive settings*/
@media only screen and (max-width:600px){

    
    #ninja-slider li .cap1 {
        font-size:20px;
    }
}


.slider-inner-category #ninja-slider-prev {display:none;}
.slider-inner-category #ninja-slider-next {display:none;}

.slider-inner-category  #ninja-slider-pager {    text-align: right;
    display: inherit;}

.slider-inner-invetor #ninja-slider-prev {
    left: -57px;
}
.slider-inner-invetor #ninja-slider-next {
    right: -57px;
}
.slider-inner-invetor-two #ninja-slider-prev {
    left: -57px;
}
.slider-inner-invetor-two #ninja-slider-next {
    right: -57px;
}

.slider-inner-invetor #ninja-slider-prev::before, .slider-inner-invetor #ninja-slider-next::before {
   
    border-left: 2px solid rgba(41, 45, 125, 0.87);;
    border-top: 2px solid rgba(41, 45, 125, 0.87);;
}

.slider-inner-invetor-two #ninja-slider-prev::before, .slider-inner-invetor-two #ninja-slider-next::before {
   
    border-left: 2px solid rgba(41, 45, 125, 0.87);;
    border-top: 2px solid rgba(41, 45, 125, 0.87);;
}



/* ninja slider 2 */

/* Visit http://www.menucool.com/responsive-slider for instructions */

#ninja-slider2 {
    width:100%;
    margin:0 auto;
    overflow:hidden;
    box-sizing:border-box;
}

#ninja-slider2.fullscreen {
    background:#000;
}


#ninja-slider2 .fs-icon {
    top:6px;
    right:6px;
    width:60px;
    height:26px;
    background: rgba(0,0,0,0.2);
    z-index:2;
    color:white;
    text-align:center;
    font:bold 11px/26px arial;
    border:1px solid rgba(255,255,255,0.3);
    border-radius:2px;
    opacity:0;
    -webkit-transition:opacity 0.8s;
    transition:opacity 0.8s;
}

#ninja-slider2 .slider-inner:hover .fs-icon,
#ninja-slider2.fullscreen .fs-icon {
    opacity: 1;
}
#ninja-slider2.fullscreen div.fs-icon {
    right:30px;
}

#ninja-slider2 .fs-icon::before {      
    content:"EXPAND";
    display:block;
}

#ninja-slider2.fullscreen .fs-icon::before {
    content:"CLOSE";
}


#ninja-slider2 .slider-inner {
    margin:0 auto;/*center-aligned */
    font-size:0px;
    position:relative;
    box-sizing:border-box;
}

#ninja-slider2 .slider-inner-invetor {
    margin:0 auto;/*center-aligned */
    font-size:0px;
    width:80%;
    position:relative;
    box-sizing:border-box;
}

#ninja-slider2 .slider-inner-invetor-two {
    margin:0 auto;/*center-aligned */
    font-size:0px;
    width:640px;
    position:relative;
    box-sizing:border-box;
}

#ninja-slider2.fullscreen .slider-inner {
    width:100%;
}

#ninja-slider2 ul {
    position:relative;
    list-style:none;
    padding:0;
    box-sizing:border-box;
}

#ninja-slider2 li {
    
    width:100%;
    height:100%;
    top:0;
    left:0;
    position: absolute;
    font-size:12px;
    list-style:none;
    margin:0;
    padding:0;
    opacity:0;
    overflow:hidden;
    box-sizing:border-box;
}

#ninja-slider2 li.ns-show {
    opacity:1;
}



/* --------- slider image ------- */  
#ninja-slider2 .ns-img {
    //background-color:rgba(0,0,0,0.3);
    background-size:contain;/*Note: If transitionType is zoom, it will be overridden to cover.*/
    /*decorate border*/
    box-shadow: inset 0 0 2px rgba(255,255,255,.3);
    cursor:default;
    display:block;
    position: absolute;
    width:100%;
    height:100%;
    background-repeat:no-repeat;
    background-position:center center;
}


/*---------- Captions -------------------*/
#ninja-slider2 .caption {
    padding:20px 40px;
    margin:auto;
    left:0;right:0;
    top:auto;bottom:0px;
    width:auto;
    text-align:right;
    font-family:sans-serif;
    font-size:11px;
    color:rgba(255,255,255,0.2);
    background:none;
    /*background:rgba(0,0,0,0.12);*/
    position:absolute;
    box-sizing:border-box;
}
#ninja-slider2 li .cap1 {
    color:rgba(255,255,255,0.5);
    font:bold 32px arial;
    text-align:center;

    opacity:0;
    bottom:40%;
    -webkit-transition:bottom 1.3s, opacity 1s;
    transition:bottom 1.3s, opacity 1s;
}

/*The cap1 of the current slide */
#ninja-slider2 li[class*='-'] .cap1 {
    opacity:1;
    bottom:20%;
}
#ninja-slider2 li .cap2 {
    color:rgba(255, 162, 0, 0.5);
    font-size:23px;
    bottom:15%;
    -webkit-transition-delay: 1s;
    transition-delay: 1s;
}

/*The cap2 of the current slide */
#ninja-slider2 li[class*='-s'] .cap2 {
    bottom:10%;
}


/* ---------Arrow buttons ------- */   
/* The arrow button id should be: slider id + ("-prev", "-next", and "-pause-play") */ 
#ninja-slider2-pause-play { display:none;}  

#ninja-slider2-prev, #ninja-slider2-next
{
    position: absolute;
    display:inline-block;
    width:42px;
    height:56px;
    line-height:56px;
    top: 50%;
    margin-top:-51px;/*pager's height should also be counted*/
    background-color:#ccc\9;/*IE8 hack*/
    backface-visibility:hidden;
    color:white;
    overflow:hidden;
    white-space:nowrap;
    -webkit-user-select: none;
    user-select:none;
    border-radius:2px;
    z-index:10; 
    font-family:sans-serif;   
    font-size:13px;
    cursor:pointer;
    -webkit-transition:all 0.7s;
    transition:all 0.7s;
}

.slider-inner:hover #ninja-slider-prev, .slider-inner:hover #ninja-slider2-next {
    opacity:1;
}


#ninja-slider2-prev {
    left: -45px; /*set it with a positive value if .slider-inner does not have max-width setting.*/
}
#ninja-slider2-next {
    right: -45px;
}

/* arrows */
#ninja-slider2-prev::before, #ninja-slider2-next::before {
    position: absolute;
    top: 17px;
    content: "";
    display: inline-block;
    width: 20px;
    height: 20px;
    border-left: 2px solid white;
    border-top: 2px solid white;
    backface-visibility:hidden;
}

#ninja-slider2-prev::before {
    -ms-transform:rotate(-45deg);/*IE 9*/
    -webkit-transform:rotate(-45deg);
    transform: rotate(-45deg);
    right:4px;
}

#ninja-slider2-next::before {
    -ms-transform:rotate(135deg);/*IE 9*/
    -webkit-transform:rotate(135deg);
    transform: rotate(135deg);
    left:4px;
}


/*pagination num within the arrow buttons*/
#ninja-slider2-prev div, #ninja-slider2-next div {display:none;}


/*------ pager(nav bullets) ------*/      
/* The pager id should be: slider id + "-pager" */
/* #ninja-slider-pager { display:none;}*/
#ninja-slider2-pager
{
    padding-top:10px; /* set the distance away from the slider */
    margin:0 auto; /* center align */
    text-align:center;
    display:table;
    font-size:0;
}
         
#ninja-slider2-pager a 
{
    display:inline-block;
    width: 12px;
    height: 12px;
    background-color: #CCC;
    font-size:0; /* Non-zero will show the index num */
    margin:2px 8px;
    cursor:pointer;
    border-radius:10px;
}

#ninja-slider2-pager a:hover 
{
    opacity:0.6;
}
#ninja-slider2-pager a.active 
{
    background-color:rgba(41, 45, 125, 0.45);
}

   

#ninja-slider2-pager, #ninja-slider2-prev, #ninja-slider2-next, #ninja-slider2-pause-play
{
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    user-select: none;
}


/*Responsive settings*/
@media only screen and (max-width:600px){

    
    #ninja-slider2 li .cap1 {
        font-size:20px;
    }
}


.slider-inner-category #ninja-slider2-prev {display:none;}
.slider-inner-category #ninja-slider2-next {display:none;}

.slider-inner-category  #ninja-slider2-pager {    text-align: right;
    display: inherit;}

.slider-inner-invetor #ninja-slider2-prev {
    left: -57px;
}
.slider-inner-invetor #ninja-slider2-next {
    right: -57px;
}
.slider-inner-invetor-two #ninja-slider2-prev {
    left: -57px;
}
.slider-inner-invetor-two #ninja-slider2-next {
    right: -57px;
}

.slider-inner-invetor #ninja-slider2-prev::before, .slider-inner-invetor #ninja-slider2-next::before {
   
    border-left: 2px solid rgba(41, 45, 125, 0.87);;
    border-top: 2px solid rgba(41, 45, 125, 0.87);;
}

.slider-inner2-invetor-two #ninja-slider2-prev::before, .slider-inner2-invetor-two #ninja-slider2-next::before {
   
    border-left: 2px solid rgba(41, 45, 125, 0.87);;
    border-top: 2px solid rgba(41, 45, 125, 0.87);;
}

/* ninja slider 2 */

/* Visit http://www.menucool.com/responsive-slider for instructions */

#ninja-slider3 {
    width:100%;
    margin:0 auto;
    overflow:hidden;
    box-sizing:border-box;
}

#ninja-slider3.fullscreen {
    background:#000;
}


#ninja-slider3 .fs-icon {
    top:6px;
    right:6px;
    width:60px;
    height:26px;
    background: rgba(0,0,0,0.2);
    z-index:2;
    color:white;
    text-align:center;
    font:bold 11px/26px arial;
    border:1px solid rgba(255,255,255,0.3);
    border-radius:2px;
    opacity:0;
    -webkit-transition:opacity 0.8s;
    transition:opacity 0.8s;
}

#ninja-slider3 .slider-inner:hover .fs-icon,
#ninja-slider3.fullscreen .fs-icon {
    opacity: 1;
}
#ninja-slider3.fullscreen div.fs-icon {
    right:30px;
}

#ninja-slider3 .fs-icon::before {      
    content:"EXPAND";
    display:block;
}

#ninja-slider3.fullscreen .fs-icon::before {
    content:"CLOSE";
}


#ninja-slider3 .slider-inner {
    margin:0 auto;/*center-aligned */
    font-size:0px;
    position:relative;
    box-sizing:border-box;
}

#ninja-slider3 .slider-inner-invetor {
    margin:0 auto;/*center-aligned */
    font-size:0px;
    width:80%;
    position:relative;
    box-sizing:border-box;
}

#ninja-slider3 .slider-inner-invetor-two {
    margin:0 auto;/*center-aligned */
    font-size:0px;
    width:640px;
    position:relative;
    box-sizing:border-box;
}

#ninja-slider3.fullscreen .slider-inner {
    width:100%;
}

#ninja-slider3 ul {
    position:relative;
    list-style:none;
    padding:0;
    box-sizing:border-box;
}

#ninja-slider3 li {
    
    width:100%;
    height:100%;
    top:0;
    left:0;
    position: absolute;
    font-size:12px;
    list-style:none;
    margin:0;
    padding:0;
    opacity:0;
    overflow:hidden;
    box-sizing:border-box;
}

#ninja-slider3 li.ns-show {
    opacity:1;
}



/* --------- slider image ------- */  
#ninja-slider3 .ns-img {
    background-color:rgba(0,0,0,0.3);
    background-size:contain;/*Note: If transitionType is zoom, it will be overridden to cover.*/
    /*decorate border*/
    box-shadow: inset 0 0 2px rgba(255,255,255,.3);
    cursor:default;
    display:block;
    position: absolute;
    width:100%;
    height:100%;
    background-repeat:no-repeat;
    background-position:center center;
}


/*---------- Captions -------------------*/
#ninja-slider3 .caption {
    padding:20px 40px;
    margin:auto;
    left:0;right:0;
    top:auto;bottom:0px;
    width:auto;
    text-align:right;
    font-family:sans-serif;
    font-size:11px;
    color:rgba(255,255,255,0.2);
    background:none;
    /*background:rgba(0,0,0,0.12);*/
    position:absolute;
    box-sizing:border-box;
}
#ninja-slider3 li .cap1 {
    color:rgba(255,255,255,0.5);
    font:bold 32px arial;
    text-align:center;

    opacity:0;
    bottom:40%;
    -webkit-transition:bottom 1.3s, opacity 1s;
    transition:bottom 1.3s, opacity 1s;
}

/*The cap1 of the current slide */
#ninja-slider3 li[class*='-'] .cap1 {
    opacity:1;
    bottom:20%;
}
#ninja-slider3 li .cap2 {
    color:rgba(255, 162, 0, 0.5);
    font-size:23px;
    bottom:15%;
    -webkit-transition-delay: 1s;
    transition-delay: 1s;
}

/*The cap2 of the current slide */
#ninja-slider3 li[class*='-s'] .cap2 {
    bottom:10%;
}


/* ---------Arrow buttons ------- */   
/* The arrow button id should be: slider id + ("-prev", "-next", and "-pause-play") */ 
#ninja-slider3-pause-play { display:none;}  

#ninja-slider3-prev, #ninja-slider3-next
{
    position: absolute;
    display:inline-block;
    width:42px;
    height:56px;
    line-height:56px;
    top: 50%;
    margin-top:-51px;/*pager's height should also be counted*/
    background-color:#ccc\9;/*IE8 hack*/
    backface-visibility:hidden;
    color:white;
    overflow:hidden;
    white-space:nowrap;
    -webkit-user-select: none;
    user-select:none;
    border-radius:2px;
    z-index:10; 
    font-family:sans-serif;   
    font-size:13px;
    cursor:pointer;
    -webkit-transition:all 0.7s;
    transition:all 0.7s;
}

.slider-inner:hover #ninja-slider3-prev, .slider-inner:hover #ninja-slider3-next {
    opacity:1;
}


#ninja-slider3-prev {
    left: -45px; /*set it with a positive value if .slider-inner does not have max-width setting.*/
}
#ninja-slider3-next {
    right: -45px;
}

/* arrows */
#ninja-slider3-prev::before, #ninja-slider3-next::before {
    position: absolute;
    top: 17px;
    content: "";
    display: inline-block;
    width: 20px;
    height: 20px;
    border-left: 2px solid white;
    border-top: 2px solid white;
    backface-visibility:hidden;
}

#ninja-slider3-prev::before {
    -ms-transform:rotate(-45deg);/*IE 9*/
    -webkit-transform:rotate(-45deg);
    transform: rotate(-45deg);
    right:4px;
}

#ninja-slider3-next::before {
    -ms-transform:rotate(135deg);/*IE 9*/
    -webkit-transform:rotate(135deg);
    transform: rotate(135deg);
    left:4px;
}


/*pagination num within the arrow buttons*/
#ninja-slider3-prev div, #ninja-slider3-next div {display:none;}


/*------ pager(nav bullets) ------*/      
/* The pager id should be: slider id + "-pager" */
/* #ninja-slider-pager { display:none;}*/
#ninja-slider3-pager
{
    padding-top:10px; /* set the distance away from the slider */
    margin:0 auto; /* center align */
    text-align:center;
    display:table;
    font-size:0;
}
         
#ninja-slider3-pager a 
{
    display:inline-block;
    width: 12px;
    height: 12px;
    background-color: #CCC;
    font-size:0; /* Non-zero will show the index num */
    margin:2px 8px;
    cursor:pointer;
    border-radius:10px;
}

#ninja-slider3-pager a:hover 
{
    opacity:0.6;
}
#ninja-slider3-pager a.active 
{
    background-color:rgba(41, 45, 125, 0.45);
}

   

#ninja-slider3-pager, #ninja-slider3-prev, #ninja-slider3-next, #ninja-slider3-pause-play
{
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    user-select: none;
}


/*Responsive settings*/
@media only screen and (max-width:600px){

    
    #ninja-slider3 li .cap1 {
        font-size:20px;
    }
}


.slider-inner-category #ninja-slider3-prev {display:none;}
.slider-inner-category #ninja-slider3-next {display:none;}

.slider-inner-category  #ninja-slider3-pager {    text-align: right;
    display: inherit;}

.slider-inner-invetor #ninja-slider3-prev {
    left: -57px;
}
.slider-inner-invetor #ninja-slider3-next {
    right: -57px;
}
.slider-inner-invetor-two #ninja-slider3-prev {
    left: -57px;
}
.slider-inner-invetor-two #ninja-slider3-next {
    right: -57px;
}

.slider-inner-invetor #ninja-slider3-prev::before, .slider-inner-invetor #ninja-slider3-next::before {
   
    border-left: 2px solid rgba(41, 45, 125, 0.87);;
    border-top: 2px solid rgba(41, 45, 125, 0.87);;
}

.slider-inner3-invetor-two #ninja-slider3-prev::before, .slider-inner3-invetor-two #ninja-slider3-next::before {
   
    border-left: 2px solid rgba(41, 45, 125, 0.87);;
    border-top: 2px solid rgba(41, 45, 125, 0.87);;
}

/* ---------If there are videos ------- */ 
#ninja-slider .video, .video-playbutton-layer 
{
    top:0; left:0; border:0;
    width: 100%;height: 100%;
    text-align: center;
    background: black;
    position: absolute;    
}

#ninja-slider2 .video, .video-playbutton-layer 
{
    top:0; left:0; border:0;
    width: 100%;height: 100%;
    text-align: center;
    background: black;
    position: absolute;    
}
#ninja-slider3 .video, .video-playbutton-layer 
{
    top:0; left:0; border:0;
    width: 100%;height: 100%;
    text-align: center;
    background: black;
    position: absolute;    
}

#ninja-slider div.stopVideoIcon 
{
    position:absolute;
    width:30px;height:30px;
    top:0;right:0px;
    margin:0 0 6px 6px;
    background:rgba(0,0,0,0.7);
    border-bottom-left-radius:4px;
    cursor:pointer;
}
#ninja-slider div.stopVideoIcon::before {
    content:"+";
    color:white;
    font:bold 30px/30px arial;
    -webkit-transform:rotate(45deg);
    transform: rotate(45deg);
    display:block;
}

#ninja-slider2 div.stopVideoIcon 
{
    position:absolute;
    width:30px;height:30px;
    top:0;right:0px;
    margin:0 0 6px 6px;
    background:rgba(0,0,0,0.7);
    border-bottom-left-radius:4px;
    cursor:pointer;
}
#ninja-slider2 div.stopVideoIcon::before {
    content:"+";
    color:white;
    font:bold 30px/30px arial;
    -webkit-transform:rotate(45deg);
    transform: rotate(45deg);
    display:block;
}

#ninja-slider3 div.stopVideoIcon 
{
    position:absolute;
    width:30px;height:30px;
    top:0;right:0px;
    margin:0 0 6px 6px;
    background:rgba(0,0,0,0.7);
    border-bottom-left-radius:4px;
    cursor:pointer;
}
#ninja-slider3 div.stopVideoIcon::before {
    content:"+";
    color:white;
    font:bold 30px/30px arial;
    -webkit-transform:rotate(45deg);
    transform: rotate(45deg);
    display:block;
}


/* ninja slider home2 */

/* Visit http://www.menucool.com/responsive-slider for instructions */

#ninja-slider-home2 {
    width:100%;
    margin:0 auto;
    overflow:hidden;
    box-sizing:border-box;
}

#ninja-slider-home2.fullscreen {
    background:#000;
}


#ninja-slider-home2 .fs-icon {
    top:6px;
    right:6px;
    width:60px;
    height:26px;
    background: rgba(0,0,0,0.2);
    z-index:2;
    color:white;
    text-align:center;
    font:bold 11px/26px arial;
    border:1px solid rgba(255,255,255,0.3);
    border-radius:2px;
    opacity:0;
    -webkit-transition:opacity 0.8s;
    transition:opacity 0.8s;
}

#ninja-slider-home2 .slider-inner:hover .fs-icon,
#ninja-slider-home2.fullscreen .fs-icon {
    opacity: 1;
}
#ninja-slider-home2.fullscreen div.fs-icon {
    right:30px;
}

#ninja-slider-home2 .fs-icon::before {      
    content:"EXPAND";
    display:block;
}

#ninja-slider-home2.fullscreen .fs-icon::before {
    content:"CLOSE";
}


#ninja-slider-home2 .slider-inner {
    margin:0 auto;/*center-aligned */
    font-size:0px;
    position:relative;
    box-sizing:border-box;
}

#ninja-slider-home2 .slider-inner-invetor {
    margin:0 auto;/*center-aligned */
    font-size:0px;
    width:80%;
    position:relative;
    box-sizing:border-box;
}

#ninja-slider-home2 .slider-inner-invetor-two {
    margin:0 auto;/*center-aligned */
    font-size:0px;
    width:640px;
    position:relative;
    box-sizing:border-box;
}

#ninja-slider-home2.fullscreen .slider-inner {
    width:100%;
}

#ninja-slider-home2 ul {
    position:relative;
    list-style:none;
    padding:0;
    box-sizing:border-box;
}

#ninja-slider-home2 li {
    
    width:100%;
    height:100%;
    top:0;
    left:0;
    position: absolute;
    font-size:12px;
    list-style:none;
    margin:0;
    padding:0;
    opacity:0;
    overflow:hidden;
    box-sizing:border-box;
}

#ninja-slider-home2 li.ns-show {
    opacity:1;
}



/* --------- slider image ------- */  
#ninja-slider-home2 .ns-img {
    background-color:inherit;
    background-size:contain;/*Note: If transitionType is zoom, it will be overridden to cover.*/
    /*decorate border*/
    box-shadow: inset 0 0 2px rgba(255,255,255,.3);
    cursor:default;
    display:block;
    position: absolute;
    width:100%;
    height:100%;
    background-repeat:no-repeat;
    background-position:center center;
}


/*---------- Captions -------------------*/
#ninja-slider-home2 .caption {
    padding:20px 40px;
    margin:auto;
    left:0;right:0;
    top:auto;bottom:0px;
    width:auto;
    text-align:right;
    font-family:sans-serif;
    font-size:11px;
    color:rgba(255,255,255,0.2);
    background:none;
    /*background:rgba(0,0,0,0.12);*/
    position:absolute;
    box-sizing:border-box;
}
#ninja-slider-home2 li .cap1 {
    color:rgba(255,255,255,0.5);
    font:bold 32px arial;
    text-align:center;

    opacity:0;
    bottom:40%;
    -webkit-transition:bottom 1.3s, opacity 1s;
    transition:bottom 1.3s, opacity 1s;
}

/*The cap1 of the current slide */
#ninja-slider-home2 li[class*='-'] .cap1 {
    opacity:1;
    bottom:20%;
}
#ninja-slider-home2 li .cap2 {
    color:rgba(255, 162, 0, 0.5);
    font-size:23px;
    bottom:15%;
    -webkit-transition-delay: 1s;
    transition-delay: 1s;
}

/*The cap2 of the current slide */
#ninja-slider-home2 li[class*='-s'] .cap2 {
    bottom:10%;
}


/* ---------Arrow buttons ------- */   
/* The arrow button id should be: slider id + ("-prev", "-next", and "-pause-play") */ 
#ninja-slider-home2-pause-play { display:none;}  

#ninja-slider-home2-prev, #ninja-slider-home2-next
{
    position: absolute;
    display:inline-block;
    width:42px;
    height:56px;
    line-height:56px;
    top: 50%;
    margin-top:-51px;/*pager's height should also be counted*/
    background-color:#ccc\9;/*IE8 hack*/
    backface-visibility:hidden;
    color:white;
    overflow:hidden;
    white-space:nowrap;
    -webkit-user-select: none;
    user-select:none;
    border-radius:2px;
    z-index:10; 
    font-family:sans-serif;   
    font-size:13px;
    cursor:pointer;
    -webkit-transition:all 0.7s;
    transition:all 0.7s;
}

.slider-inner:hover #ninja-slider-prev, .slider-inner:hover #ninja-slider-home2-next {
    opacity:1;
}


#ninja-slider-home2-prev {
    left: -45px; /*set it with a positive value if .slider-inner does not have max-width setting.*/
}
#ninja-slider-home2-next {
    right: -45px;
}

/* arrows */
#ninja-slider-home2-prev::before, #ninja-slider-home2-next::before {
    position: absolute;
    top: 17px;
    content: "";
    display: inline-block;
    width: 20px;
    height: 20px;
    border-left: 2px solid white;
    border-top: 2px solid white;
    backface-visibility:hidden;
}

#ninja-slider-home2-prev::before {
    -ms-transform:rotate(-45deg);/*IE 9*/
    -webkit-transform:rotate(-45deg);
    transform: rotate(-45deg);
    right:4px;
}

#ninja-slider-home2-next::before {
    -ms-transform:rotate(135deg);/*IE 9*/
    -webkit-transform:rotate(135deg);
    transform: rotate(135deg);
    left:4px;
}


/*pagination num within the arrow buttons*/
#ninja-slider-home2-prev div, #ninja-slider-home2-next div {display:none;}


/*------ pager(nav bullets) ------*/      
/* The pager id should be: slider id + "-pager" */
/* #ninja-slider-pager { display:none;}*/
#ninja-slider-home2-pager
{
    padding-top:10px; /* set the distance away from the slider */
    margin:0 auto; /* center align */
    text-align:center;
    display:table;
    font-size:0;
}
         
#ninja-slider-home2-pager a 
{
    display:inline-block;
    width: 12px;
    height: 12px;
    background-color: #CCC;
    font-size:0; /* Non-zero will show the index num */
    margin:2px 8px;
    cursor:pointer;
    border-radius:10px;
}

#ninja-slider-home2-pager a:hover 
{
    opacity:0.6;
}
#ninja-slider-home2-pager a.active 
{
    background-color:rgba(41, 45, 125, 0.45);
}

   

#ninja-slider-home2-pager, #ninja-slider-home2-prev, #ninja-slider-home2-next, #ninja-slider-home2-pause-play
{
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    user-select: none;
}


/*Responsive settings*/
@media only screen and (max-width:600px){

    
    #ninja-slider-home2 li .cap1 {
        font-size:20px;
    }
}


.slider-inner-category #ninja-slider-home2-prev {display:none;}
.slider-inner-category #ninja-slider-home2-next {display:none;}

.slider-inner-category  #ninja-slider-home2-pager {    text-align: right;
    display: inherit;}

.slider-inner-invetor #ninja-slider-home2-prev {
    left: -57px;
}
.slider-inner-invetor #ninja-slider-home2-next {
    right: -57px;
}
.slider-inner-invetor-two #ninja-slider-home2-prev {
    left: -57px;
}
.slider-inner-invetor-two #ninja-slider-home2-next {
    right: -57px;
}

.slider-inner-invetor #ninja-slider-home2-prev::before, .slider-inner-invetor #ninja-slider-home2-next::before {
   
    border-left: 2px solid rgba(41, 45, 125, 0.87);;
    border-top: 2px solid rgba(41, 45, 125, 0.87);;
}

.slider-inner2-invetor-two #ninja-slider-home2-prev::before, .slider-inner2-invetor-two #ninja-slider-home2-next::before {
   
    border-left: 2px solid rgba(41, 45, 125, 0.87);;
    border-top: 2px solid rgba(41, 45, 125, 0.87);;
}


/* Visit http://www.menucool.com/responsive-slider for instructions home3 */

#ninja-slider-home3 {
    width:100%;
    margin:0 auto;
    overflow:hidden;
    box-sizing:border-box;
}

#ninja-slider-home3.fullscreen {
    background:#000;
}


#ninja-slider-home3 .fs-icon {
    top:6px;
    right:6px;
    width:60px;
    height:26px;
    background: rgba(0,0,0,0.2);
    z-index:2;
    color:white;
    text-align:center;
    font:bold 11px/26px arial;
    border:1px solid rgba(255,255,255,0.3);
    border-radius:2px;
    opacity:0;
    -webkit-transition:opacity 0.8s;
    transition:opacity 0.8s;
}

#ninja-slider-home3 .slider-inner:hover .fs-icon,
#ninja-slider-home3.fullscreen .fs-icon {
    opacity: 1;
}
#ninja-slider-home3.fullscreen div.fs-icon {
    right:30px;
}

#ninja-slider-home3 .fs-icon::before {      
    content:"EXPAND";
    display:block;
}

#ninja-slider-home3.fullscreen .fs-icon::before {
    content:"CLOSE";
}


#ninja-slider-home3 .slider-inner {
    margin:0 auto;/*center-aligned */
    font-size:0px;
    position:relative;
    box-sizing:border-box;
}

#ninja-slider-home3 .slider-inner-invetor {
    margin:0 auto;/*center-aligned */
    font-size:0px;
    width:80%;
    position:relative;
    box-sizing:border-box;
}

#ninja-slider-home3 .slider-inner-invetor-two {
    margin:0 auto;/*center-aligned */
    font-size:0px;
    width:79%;
    position:relative;
    box-sizing:border-box;
}

#ninja-slider-home3.fullscreen .slider-inner {
    width:100%;
}

#ninja-slider-home3 ul {
    position:relative;
    list-style:none;
    padding:0;
    box-sizing:border-box;
}

#ninja-slider-home3 li {
    
    width:100%;
    height:100%;
    top:0;
    left:0;
    position: absolute;
    font-size:12px;
    list-style:none;
    margin:0;
    padding:0;
    opacity:0;
    overflow:hidden;
    box-sizing:border-box;
}

#ninja-slider-home3 li.ns-show {
    opacity:1;
}



/* --------- slider image ------- */  
#ninja-slider-home3 .ns-img {
    
    background-color:inherit;
    background-size:contain;/*Note: If transitionType is zoom, it will be overridden to cover.*/
    /*decorate border*/
    box-shadow: inset 0 0 2px rgba(255,255,255,.3);
    cursor:default;
    display:block;
    position: absolute;
    width:100%;
    height:100%;
    background-repeat:no-repeat;
    background-position:center center;
}


/*---------- Captions -------------------*/
#ninja-slider-home3 .caption {
    padding:20px 40px;
    margin:auto;
    left:0;right:0;
    top:auto;bottom:0px;
    width:auto;
    text-align:right;
    font-family:sans-serif;
    font-size:11px;
    color:rgba(255,255,255,0.2);
    background:none;
    /*background:rgba(0,0,0,0.12);*/
    position:absolute;
    box-sizing:border-box;
}
#ninja-slider-home3 li .cap1 {
    color:rgba(255,255,255,0.5);
    font:bold 32px arial;
    text-align:center;

    opacity:0;
    bottom:40%;
    -webkit-transition:bottom 1.3s, opacity 1s;
    transition:bottom 1.3s, opacity 1s;
}

/*The cap1 of the current slide */
#ninja-slider-home3 li[class*='-'] .cap1 {
    opacity:1;
    bottom:20%;
}
#ninja-slider-home3 li .cap2 {
    color:rgba(255, 162, 0, 0.5);
    font-size:23px;
    bottom:15%;
    -webkit-transition-delay: 1s;
    transition-delay: 1s;
}

/*The cap2 of the current slide */
#ninja-slider-home3 li[class*='-s'] .cap2 {
    bottom:10%;
}


/* ---------Arrow buttons ------- */   
/* The arrow button id should be: slider id + ("-prev", "-next", and "-pause-play") */ 
#ninja-slider-home3-pause-play { display:none;}  

#ninja-slider-home3-prev, #ninja-slider-home3-next
{
    position: absolute;
    display:inline-block;
    width:42px;
    height:56px;
    line-height:56px;
    top: 50%;
    margin-top:-51px;/*pager's height should also be counted*/
    background-color:#ccc\9;/*IE8 hack*/
    backface-visibility:hidden;
    color:white;
    overflow:hidden;
    white-space:nowrap;
    -webkit-user-select: none;
    user-select:none;
    border-radius:2px;
    z-index:10; 
    font-family:sans-serif;   
    font-size:13px;
    cursor:pointer;
    -webkit-transition:all 0.7s;
    transition:all 0.7s;
}

.slider-inner:hover #ninja-slider-home3-prev, .slider-inner:hover #ninja-slider-home3-next {
    opacity:1;
}


#ninja-slider-home3-prev {
    left: 10px; /*set it with a positive value if .slider-inner does not have max-width setting.*/
}
#ninja-slider-home3-next {
    right: 10px;
}

/* arrows */
#ninja-slider-home3-prev::before, #ninja-slider-home3-next::before {
    position: absolute;
    top: 17px;
    content: "";
    display: inline-block;
    width: 20px;
    height: 20px;
    border-left: 2px solid white;
    border-top: 2px solid white;
    backface-visibility:hidden;
}

#ninja-slider-home3-prev::before {
    -ms-transform:rotate(-45deg);/*IE 9*/
    -webkit-transform:rotate(-45deg);
    transform: rotate(-45deg);
    right:4px;
}

#ninja-slider-home3-next::before {
    -ms-transform:rotate(135deg);/*IE 9*/
    -webkit-transform:rotate(135deg);
    transform: rotate(135deg);
    left:4px;
}


/*pagination num within the arrow buttons*/
#ninja-slider-home3-prev div, #ninja-slider-home3-next div {display:none;}


/*------ pager(nav bullets) ------*/      
/* The pager id should be: slider id + "-pager" */
/* #ninja-slider-home3-pager { display:none;}*/
#ninja-slider-home3-pager
{
    padding-top:10px; /* set the distance away from the slider */
    margin:0 auto; /* center align */
    text-align:center;
    display:table;
    font-size:0;
}
         
#ninja-slider-home3-pager a 
{
    display:inline-block;
    width: 12px;
    height: 12px;
    background-color: #CCC;
    font-size:0; /* Non-zero will show the index num */
    margin:2px 8px;
    cursor:pointer;
    border-radius:10px;
}

#ninja-slider-home3-pager a:hover 
{
    opacity:0.6;
}
#ninja-slider-home3-pager a.active 
{
    background-color:rgba(41, 45, 125, 0.45);
}

   

#ninja-slider-home3-pager, #ninja-slider-home3-prev, #ninja-slider-home3-next, #ninja-slider-home3-pause-play
{
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    user-select: none;
}


/*Responsive settings*/
@media only screen and (max-width:600px){

    
    #ninja-slider-home3 li .cap1 {
        font-size:20px;
    }
}


.slider-inner-category #ninja-slider-home3-prev {display:none;}
.slider-inner-category #ninja-slider-home3-next {display:none;}

.slider-inner-category  #ninja-slider-home3-pager {    text-align: right;
    display: inherit;}

.slider-inner-invetor #ninja-slider-home3-prev {
    left: -57px;
}
.slider-inner-invetor #ninja-slider-home3-next {
    right: -57px;
}
.slider-inner-invetor-two #ninja-slider-home3-prev {
    left: -57px;
}
.slider-inner-invetor-two #ninja-slider-home3-next {
    right: -57px;
}

.slider-inner-invetor #ninja-slider-home3-prev::before, .slider-inner-invetor #ninja-slider-home3-next::before {
   
    border-left: 2px solid rgba(41, 45, 125, 0.87);;
    border-top: 2px solid rgba(41, 45, 125, 0.87);;
}

.slider-inner-invetor-two #ninja-slider-home3-prev::before, .slider-inner-invetor-two #ninja-slider-home3-next::before {
   
    border-left: 2px solid rgba(41, 45, 125, 0.87);;
    border-top: 2px solid rgba(41, 45, 125, 0.87);;
}

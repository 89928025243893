@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;1,100;1,300;1,400&display=swap');
.timestamp
{
    float:right;
    font-size: 10px;
}
@mixin font-bold {
    font-family: 'Source Sans Pro', sans-serif;
    font-weight: 600;
}
@mixin font {
    font-family: 'Source Sans Pro', sans-serif;
    font-weight: 400;
}
@mixin placeholder {
    &::-webkit-input-placeholder {
        @content;
    }
    &:-moz-placeholder {
        @content;
    }
    &::-moz-placeholder {
        @content;
    }
    &:-ms-input-placeholder {
        @content;
    }
}

*, *:before, *:after {
    box-sizing: border-box;
}

.wrapper-workstream {
    height: 550px;
    background-color: #f8fafc;
    border-left: 1px solid #dee2e6;
    border-right: 1px solid #dee2e6;
    border-bottom: 1px solid #dee2e6;
}
.inventor-wrapper-workstream 
{
    height: 850px !important;
}
.container-data {
    position: relative;
    top: 52%;
    left: 50%;
    width: 100% !important;
    height: 100%;
    background-color: var(--white);
    transform: translate(-50%, -50%);
    .left {
        float: left;
        width: 37.6%;
        height: 100%;
        border: 1px solid var(--light);
        background-color: var(--white);
        .top {
            position: relative;
            width: 100%;
            height: 96px;
            padding: 29px;
            &:after {
                position: absolute;
                bottom: 0;
                left: 50%;
                display: block;
                width: 80%;
                height: 1px;
                content: '';
                background-color: var(--light);
                transform: translate(-50%, 0);
            }
        }
        input {
            float: left;
            height: 42px;
            padding: 0 15px;
            border: 1px solid var(--light);
            background-color: #eceff1;
            border-radius: 21px;
            @include font();
            &:focus {
                outline: none;
            }
        }
        a.search {
            display: block;
            float: left;
            width: 42px;
            height: 42px;
            margin-left: 10px;
            border: 1px solid var(--light);
            background-color: var(--blue);
            background-image: url('https://s3-us-west-2.amazonaws.com/s.cdpn.io/382994/name-type.png');
            background-repeat: no-repeat;
            background-position: top 12px left 14px;
            border-radius: 50%;
        }
        .people {
            margin-left: -1px;
            border-right: 1px solid var(--light);
            border-left: 1px solid var(--light);
            width: calc(100% + 2px);
              list-style-type: none;
            .person {
                position: relative;
                width: 100%;
                padding: 12px 10% 16px;
                cursor: pointer;
                background-color: var(--white);
                &:after {
                    position: absolute;
                    bottom: 0;
                    left: 50%;
                    display: block;
                    width: 80%;
                    height: 1px;
                    content: '';
                    background-color: var(--light);
                    transform: translate(-50%, 0);
                }
                img {
                    float: left;
                    width: 40px;
                    height: 40px;
                    margin-right: 12px;
                    border-radius: 50%;
                }
                .name {
                    font-size: 14px;
                    line-height: 22px;
                    color: var(--dark);
                    @include font-bold;
                }
                .time {
                    font-size: 14px;
                    position: absolute;
                    top: 16px;
                    right: 10%;
                    padding: 0 0 5px 5px;
                    color: var(--grey);
                    background-color: var(--white);
                }
                .preview {
                    font-size: 14px;
                    display: inline-block;
                    overflow: auto !important;
                    width: 70%;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    color: var(--grey);
                }
                &.active,&:hover {
                    margin-top: -1px;
                    margin-left: -1px;
                    padding-top: 13px;
                    border: 0;
                    background-color: var(--blue);
                    width: calc(100% + 2px);
                    padding-left: calc(10% + 1px);
                    span {
                        color: var(--white);
                        background: transparent;
                    }
                    &:after {
                        display: none;
                    }
                }
            }
        }
    }
    .right {
        position: relative;
        float: left;
        width: 100%;
        height: 97%;
        .top {
            width: 100%;
            height: 47px;
            padding: 15px 29px;
            background-color: #eceff1;
            span {
                font-size: 15px;
                color: var(--grey);
                .name {
                    color: var(--dark);
                    @include font-bold;
                }
            }
        }
        .chat {
            position: relative;
            overflow: auto;
            padding: 0 35px 92px;
            border:none !important;
            border-style: solid;
            border-color: var(--light);
            height: calc(100% - 48px);
            justify-content: flex-end;
            flex-direction: column;
            &.active-chat {
                display: block;
                display: flex;
                .bubble {
                    transition-timing-function: cubic-bezier(.4,-.04, 1, 1);
                    @for $i from 1 through 10 {
                        &:nth-of-type(#{$i}) {
                            animation-duration: .15s * $i;
                        }
                    }
                }
            }
        }
        .write {
            position: absolute;
            left: 30px;
            height: 72px;
            padding-left: 8px;
            border: 1px solid var(--light);
            background-color: #eceff1;
            width: calc(100% - 58px);
            border-radius: 5px;
            input {
                font-size: 16px;
                float: left;
                width: 80%;
                height: 70px;
                padding: 0 10px;
                color: var(--dark);
                border: 0;
                outline: none;
                background-color: #eceff1;
                @include font;
            }
            .write-link {
                &.attach {
                    &:before {
                        display: inline-block;
                        float: left;
                        width: 20px;
                        height: 42px;
                        content: '';
                        background-image: url('https://s3-us-west-2.amazonaws.com/s.cdpn.io/382994/attachment.png');
                        background-repeat: no-repeat;
                        background-position: center;
                    }
                }
                &.smiley {
                    &:before {
                        display: inline-block;
                        float: left;
                        width: 20px;
                        height: 42px;
                        content: '';
                        background-image: url('https://s3-us-west-2.amazonaws.com/s.cdpn.io/382994/smiley.png');
                        background-repeat: no-repeat;
                        background-position: center;
                    }
                }
                &.send {
                    &:before {
                        display: inline-block;
                        float: left;
                        width: 20px;
                        height: 42px;
                        margin-left: 11px;
                        content: '';
                        background-image: url('https://s3-us-west-2.amazonaws.com/s.cdpn.io/382994/send.png');
                        background-repeat: no-repeat;
                        background-position: center;
                    }
                }
            }
        }
        .bubble {
            font-size: 16px;
            position: relative;
            display: inline-block;
            clear: both;
            margin-bottom: 8px;
            padding: 13px 14px;
            vertical-align: top;
            border-radius: 5px;
            &:before {
                position: absolute;
                top: 19px;
                display: block;
                width: 8px;
                height: 6px;
                content: '\00a0';
                transform: rotate(29deg) skew(-35deg);
            }
            &.you {
                float: left;
                color: var(--white);
                background-color: white;
                align-self: flex-start;
                animation-name: slideFromLeft;
                &:before {
                    left: -3px;
                    background-color: white;
                }
            }
            &.me {
                float: right;
                color: var(--dark);
                background-color: lightgreen;
                align-right: flex-end;
                animation-name: slideFromRight;
                &:before {
                    right: -3px;
                    background-color: lightgreen;
                }
            }
        }
        .conversation-start {
            position: relative;
            width: 100%;
            margin-bottom: 27px;
            text-align: center;
            span {
                font-size: 14px;
                display: inline-block;
                color: var(--grey);
                &:before,&:after {
                    position: absolute;
                    top: 10px;
                    display: inline-block;
                    width: 30%;
                    height: 1px;
                    content: '';
                    background-color: var(--light);
                }
                &:before {
                    left: 0;
                }
                &:after {
                    right: 0;
                }
            }
        }
    }
}
@keyframes slideFromLeft {
    0% {
        margin-left: -200px;
        opacity: 0;
    }
    100% {
        margin-left: 0;
        opacity: 1;
    }
}
@-webkit-keyframes slideFromLeft {
    0% {
        margin-left: -200px;
        opacity: 0;
    }
    100% {
        margin-left: 0;
        opacity: 1;
    }
}
@keyframes slideFromRight {
    0% {
        margin-right: -200px;
        opacity: 0;
    }
    100% {
        margin-right: 0;
        opacity: 1;
    }
}
@-webkit-keyframes slideFromRight {
    0% {
        margin-right: -200px;
        opacity: 0;
    }
    100% {
        margin-right: 0;
        opacity: 1;
    }
}
/*.modal-content{
    background-color:transparent;border:0px;
}*/
div.checkbox.switcher label, div.radio.switcher label {
  padding: 0;
}
div.checkbox.switcher label *, div.radio.switcher label * {
  vertical-align: middle;
}
div.checkbox.switcher label input, div.radio.switcher label input {
  display: none;
}
div.checkbox.switcher label input + span, div.radio.switcher label input + span {
  position: relative;
  display: inline-block;
  margin-right: 10px;
  width: 56px;
  height: 28px;
  background: #f2f2f2;
  border: 1px solid #eee;
  border-radius: 50px;
  transition: all 0.3s ease-in-out;
}
div.checkbox.switcher label input + span small, div.radio.switcher label input + span small {
  position: absolute;
  display: block;
  width: 50%;
  height: 100%;
  background: #fff;
  border-radius: 50%;
  transition: all 0.3s ease-in-out;
  left: 0;
}
div.checkbox.switcher label input:checked + span, div.radio.switcher label input:checked + span {
  background: #269bff;
  border-color: #269bff;
}
div.checkbox.switcher label input:checked + span small, div.radio.switcher label input:checked + span small {
  left: 50%;
}

#quote {padding: 20px; margin-bottom: 20px;}
#upload-file{
    display:none;
}

.rating-stars ul {
       list-style-type:none;
       padding:0;
       
       -moz-user-select:none;
       -webkit-user-select:none;
     }
     .rating-stars ul > li.star {
       display:inline-block;
       
     }

     /* Idle State of the stars */
     .rating-stars ul > li.star > i.fa {
       font-size:2.5em; /* Change the size of the stars */
       color:#ccc; /* Color on idle state */
     }

     /* Hover state of the stars */
     .rating-stars ul > li.star.hover > i.fa {
       color:#FFCC36;
     }

     /* Selected state of the stars */
     .rating-stars ul > li.star.selected > i.fa {
       color:#FF912C;
     }
     .fixed-btn {
         position: fixed;
         bottom: 5%;
         right: 0%;
         z-index: 99999;
     }
     .fixed-btn-edit {
         position: fixed;
         bottom: 15%;
         right: 0%;
         z-index: 99999;
     }
table#qitm
      {
          clear: both;
          width: 100%;
          border-spacing: 2px;
      }
      table#qid table.balance {
          float: right;
          width: 36%;
          margin: 0 0 3em;
          border-spacing: 2px;
      }
      table#qid,table#qitm th
       {
           font-weight: bold; 
           text-align: center; 
           background: #EEE;
           border-color: #BBB;
           border-radius: 0.25em;
           border-style: solid;
           border-width: 1px;
           padding: 0.1em;
           position: relative;
           text-align: left;
      }
      table#qid,table#qitm td
       {
            border-radius: 0.25em;
            border-style: solid;
            border-width: 1px;
            padding: 0.1em;
            position: relative;
      }

